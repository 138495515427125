/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ConfigClient {
	app?: string;
	client?: CubegamesQueuePClient;
	id?: number;
	name?: string;
}

export enum CubegamesQueuePBillingOperationType {
	PBillingOperationTypePBILLINGOPERATIONTYPECASHIN = 0,
	PBillingOperationTypePBILLINGOPERATIONTYPECASHOUT = 1,
	PBillingOperationTypePBILLINGOPERATIONTYPEWIN = 2,
	PBillingOperationTypePBILLINGOPERATIONTYPEPAY = 3,
	PBillingOperationTypePBILLINGOPERATIONTYPEJP = 4,
	PBillingOperationTypePBILLINGOPERATIONTYPEBONUS = 5,
	PBillingOperationTypePBILLINGOPERATIONTYPEROLLBACK = 6,
	PBillingOperationTypePBILLINGOPERATIONTYPEFINALBONUS = 7,
	PBillingOperationTypePBILLINGOPERATIONTYPECANCEL = 8,
	PBillingOperationTypePBILLINGOPERATIONTYPECASHDESKCOUNTERREFILL = 9,
	PBillingOperationTypePBILLINGOPERATIONTYPECASHDESKCOUNTERWITHDRAW = 10,
}

export enum CubegamesQueuePBillingRTP {
	PBillingRTPPBILLINGRTPDEFAULT = 0,
	PBillingRTPPBILLINGRTPLOW = 1,
	PBillingRTPPBILLINGRTPHEIGHT = 2,
	PBillingRTPPBILLINGRTPEXTRALOW = 3,
	PBillingRTPPBILLINGRTPULTRALOW = 4,
}

export enum CubegamesQueuePClient {
	PClientPCLIENTUNKNOWN = 0,
	PClientPCLIENTPILOT = 1,
	PClientPCLIENTFRUITSLOTS = 5,
	PClientPCLIENTMINIGAMES = 7,
}

export enum CubegamesQueuePJackpotType {
	PJackpotTypePJACKPOTTYPEUNKNOWN = 0,
	PJackpotTypePJACKPOTTYPECOMMON = 1,
	PJackpotTypePJACKPOTTYPEGROUP = 2,
}

export interface CubegamesQueuePPQueueAdminGeneratorConfiguration {
	drop_account?: number;
	name?: string;
	pilot?: CubegamesQueuePPQueueAdminGeneratorConfigurationPilot;
	prize_pool_hours?: number;
	prize_pools?: CubegamesQueuePPrizePoolConfiguration[];
}

export interface CubegamesQueuePPQueueAdminGeneratorConfigurationPilot {
	drop_probability?: number;
	max_win?: number;
	rtp?: number;
}

export interface CubegamesQueuePPrizePoolConfiguration {
	current_rtp?: number;
	drop_account_probability?: number;
	pilot?: CubegamesQueuePPQueueAdminGeneratorConfigurationPilot;
	probability?: number;
}

export interface CubegamesQueuePQueueAccountConfiguration {
	max_cashier_transaction_amount?: number;
	min_cashier_transaction_amount?: number;
}

export interface CubegamesQueuePQueueAdminAccountConfiguration {
	account_configuration?: CubegamesQueuePQueueAccountConfiguration;
	account_id?: number;
	currency?: string;
	disable_logo?: boolean;
	fb_group_name?: string;
	formats?: CubegamesQueuePQueueAdminFormats;
	jp_group_name?: string;
	jp_keys?: string[];
	language?: string;
	logo_url?: string;
	max_win?: number;
	preset_name?: string;
	services?: Record<string, CubegamesQueuePQueueDeviceConfigurationService>;
}

export interface CubegamesQueuePQueueAdminFinalBonusConfiguration {
	/** Валюта */
	currency?: string;
	/** Имя настройки финального бонуса */
	final_bonus_name?: string;
	/** ID аккаунта для наследования */
	parent_account_id?: number;
	services?: Record<
		string,
		CubegamesQueuePQueueAdminFinalBonusConfigurationService
	>;
}

export interface CubegamesQueuePQueueAdminFinalBonusConfigurationElement {
	/** от какого значение */
	from?: number;
	/** выдаем этот процент если он не 0 */
	percent?: number;
	/** выдаем эту сумму если она не 0 */
	sum?: number;
}

export interface CubegamesQueuePQueueAdminFinalBonusConfigurationService {
	/** Элементы конфигурации */
	elements?: CubegamesQueuePQueueAdminFinalBonusConfigurationElement[];
}

export interface CubegamesQueuePQueueAdminFormats {
	bet_format?: string;
	entries_format?: string;
	final_bonus_format?: string;
	jackpot_format?: string;
	promo_bonus_format?: string;
	value_format?: string;
	win_format?: string;
	winnings_format?: string;
}

export interface CubegamesQueuePQueueDeviceConfigurationService {
	bets?: number[];
	generator?: string;
	individual_generator?: boolean;
	is_active?: boolean;
	jp_keys?: string[];
	logo?: number;
	max_win?: number;
	messages?: string[];
	rtp?: CubegamesQueuePBillingRTP;
	skin?: number;
}

export interface DomainAccount {
	/** Account balances */
	account_balances?: DomainAccountBalance[];
	account_configuration?: DomainAccountConfiguration;
	/** Account finance */
	account_finance?: DomainAccountFinance;
	/** Account meta */
	account_meta?: DomainAccountMeta;
	/** Account balance */
	account_overdraft?: number;
	/** Account show balance */
	account_show_balance?: boolean;
	/** Account balance */
	balance?: number;
	created_at?: string;
	/** Currency of account */
	currency?: string;
	/** Deleted flag */
	deleted?: boolean;
	/** Description of account */
	description?: string;
	/** FinalBonus id */
	final_bonus_id?: string;
	id?: number;
	/** Jackpot id */
	jackpot_id?: string;
	/** Name of account */
	name?: string;
	/** Parent account id */
	parent_id?: number;
	/** Path to account format (/2/3/4/5) */
	path?: string;
	/** Preset id */
	preset_id?: string;
	restrictions?: string[];
}

export interface DomainAccountBalance {
	/** Account balance */
	balance?: number;
	/** Currency of account */
	currency?: string;
	id?: number;
	overdraft?: number;
}

export interface DomainAccountConfiguration {
	max_cashier_transaction_amount?: number;
	min_cashier_transaction_amount?: number;
}

export interface DomainAccountDeviceResult {
	account_id?: number;
	device_id?: string;
	device_uuid?: number[];
	name?: string;
}

export interface DomainAccountFinance {
	account_percent?: number;
}

export interface DomainAccountMainReportData {
	count_in?: number;
	count_out?: number;
	total_in?: number;
	total_out?: number;
}

export interface DomainAccountMainReportResult {
	account_id?: number;
	currency?: string;
	data?: DomainAccountMainReportData;
	date_time_key?: string;
	device_count?: number;
}

export interface DomainAccountMeta {
	account_type?: DomainAccountTypes;
	address?: string;
	contract_cost_percent?: number;
	email?: string;
	phone?: string;
	revenue_share_percent?: number;
	telegram?: string;
}

export interface DomainAccountOperationGroupResult {
	account_id?: number;
	created_at?: string;
	currency?: string;
	data?: DomainAccountMainReportData;
	device_id?: string;
	device_name?: string;
	device_uuid?: number[];
	operation_count?: number;
}

export interface DomainAccountOperationReportResult {
	account_id?: number;
	created_at?: string;
	currency?: string;
	data?: DomainAccountMainReportData;
	device_id?: string;
	device_name?: string;
	place?: number;
	trx?: number[];
	user_id?: number;
	user_idx?: string;
	user_name?: string;
}

export enum DomainAccountTypes {
	AccountTypeUnknown = 0,
	AccountTypeAgent = 1,
	AccountTypeOperator = 2,
	AccountTypeShop = 3,
}

export interface DomainCashierBalances {
	entries?: number;
	final_bonus?: number;
	float_balance?: number;
	winnings?: number;
}

export interface DomainCashierDevice {
	active?: boolean;
	created_at?: string;
	game_id?: string;
	hidden?: boolean;
	id?: string;
	module?: number;
	name?: string;
	uuid?: number[];
}

export interface DomainCommunityJackpot {
	accumulate?: number;
	border_max?: number;
	border_min?: number;
	currency?: string;
	drop_kf?: number;
	generator?: string;
	id?: number;
	percent?: number;
}

export interface DomainCounterDesk {
	account_id?: number;
	balance?: number;
	id?: number;
	last_message_id?: number;
	updated_at?: string;
}

export interface DomainCounterDeskOperation {
	cashdesk_id?: number;
	created_at?: string;
	delta_balance?: number;
	id?: number;
	status?: number;
	type?: CubegamesQueuePBillingOperationType;
	user?: DomainUserGormData;
}

export interface DomainDevice {
	account_id?: number;
	active?: boolean;
	created_at?: string;
	game_id?: number;
	id?: number;
	module?: number;
	name?: string;
	type?: DomainDeviceType;
	updated_at?: string;
	uuid?: number[];
}

export enum DomainDeviceType {
	DeviceTypeUnknown = 0,
	DeviceTypePilotMultiPlace = 1,
}

export interface DomainFinalBonus {
	configuration?: CubegamesQueuePQueueAdminFinalBonusConfiguration;
	created_at?: string;
	currency?: string;
	id?: number;
	name?: string;
	updated_at?: string;
}

export interface DomainFinance {
	/** 0-100 (100%) */
	agent_commission?: number;
	amount?: number;
	cost_status?: DomainFinanceTransactionStatus;
	created_at?: string;
	currency?: string;
	from_account_id?: number;
	id?: number;
	/** 0-100 (100%) */
	percent?: number;
	profit_status?: DomainFinanceTransactionStatus;
	to_account_id?: number;
	type?: DomainFinanceTransactionType;
}

export enum DomainFinanceTransactionStatus {
	FinanceTransactionStatusPending = 0,
	FinanceTransactionStatusPaid = 1,
}

export enum DomainFinanceTransactionType {
	FinanceTransactionTypeCash = 0,
	FinanceTransactionTypeCrypto = 1,
	FinanceTransactionTypeBank = 2,
	FinanceTransactionTypeBonus = 3,
}

export interface DomainFloatBalanceResult {
	balance?: number;
	has_overdraft?: boolean;
	overdraft?: number;
}

export interface DomainGenerator {
	config?: CubegamesQueuePPQueueAdminGeneratorConfiguration;
	id?: number;
	name?: string;
}

export interface DomainGroup {
	created_at?: string;
	id?: number;
	name?: string;
	restrictions?: string[];
	users?: DomainUser[];
}

export interface DomainJackpot {
	accumulate?: number;
	created_at?: string;
	id?: number;
	jackpot_group_id?: number;
	max?: number;
	min?: number;
	min_bet?: number;
	min_kf?: number;
	name?: string;
	order?: number;
	/** 0-100_00 (100%) */
	percent?: number;
	start_value?: number;
	time_frames?: DomainJackpotTimeFrames;
	type?: CubegamesQueuePJackpotType;
	updated_at?: string;
}

export interface DomainJackpotGroup {
	currency?: string;
	global?: boolean;
	id?: number;
	jackpots?: DomainJackpot[];
	jp_key?: string;
	name?: string;
	service_id?: number;
}

export interface DomainJackpotTimeFrame {
	from_hour?: number;
	to_hour?: number;
	week_day?: boolean[];
}

export interface DomainJackpotTimeFrames {
	frames?: DomainJackpotTimeFrame[];
}

export interface DomainPreset {
	configuration?: CubegamesQueuePQueueAdminAccountConfiguration;
	created_at?: string;
	currency?: string;
	id?: number;
	name?: string;
	updated_at?: string;
}

export interface DomainStatsAggregate {
	account_id?: number;
	billing_incoming?: number;
	billing_incoming_count?: number;
	billing_outgoing?: number;
	billing_outgoing_count?: number;
	community_jackpot?: number;
	community_jackpot_count?: number;
	currency?: string;
	date_key?: string;
	device_count?: number;
	device_id?: number;
	device_uuid?: number[];
	final_bonus?: number;
	final_bonus_count?: number;
	global_jackpot?: number;
	global_jackpot_count?: number;
	jackpot?: number;
	jackpot_count?: number;
	module?: number;
	pay?: number;
	pay_count?: number;
	place?: number;
	total_day?: number;
	total_hour?: number;
	win?: number;
	win_count?: number;
}

export interface DomainStatsChartData {
	/** BillingIncoming + BillingOutgoing */
	billing_incoming?: number;
	currency?: string;
	date_key?: string;
	/** sum */
	device_count?: number;
	/** BillingIncoming */
	incoming?: number;
	pay?: number;
	/** BillingIncoming - BillingOutgoing */
	profit?: number;
	win?: number;
}

export interface DomainUser {
	/** Root account */
	account?: DomainAccount;
	/** Root account ID */
	account_id?: number;
	/** User configuration */
	configuration?: DomainUserConfiguration;
	/** Created at */
	created_at?: string;
	email?: string;
	/** Full name */
	full_name?: string;
	/** User groups */
	groups?: DomainGroup[];
	id?: number;
	/** Language */
	language?: string;
	login?: string;
	restrictions?: string[];
	/** Time zone */
	time_zone?: string;
	/** User type */
	type?: DomainUserType;
}

export interface DomainUserAccountGroupByType {
	account_id?: number;
	count?: number;
	type?: DomainUserType;
}

export interface DomainUserConfiguration {
	pin?: string;
	pin_lock_time?: number;
}

export interface DomainUserGormData {
	ip_address?: string;
	user_id?: number;
}

export enum DomainUserType {
	UserTypeBlock = 0,
	UserTypeUser = 1,
	UserTypeReporter = 40,
	UserTypeSubManager = 45,
	UserTypeManager = 50,
	UserTypeAgent = 75,
	UserTypeSupport = 90,
	UserTypeAdmin = 100,
}

export interface TransportAccount {
	account_meta?: DomainAccountMeta;
	balance?: number;
	currency?: string;
	deleted?: boolean;
	description?: string;
	final_bonus_name?: string;
	has_overdraft?: boolean;
	id?: number;
	jackpot_name?: string;
	name?: string;
	overdraft?: number;
	parent_id?: number;
	preset_name?: string;
	restrictions?: string[];
}

export interface TransportAccountAddOverdraftRequest {
	description?: string;
	value?: number;
}

export interface TransportAccountDevice {
	active?: boolean;
	created_at?: string;
	id?: string;
	name?: string;
	type?: DomainDeviceType;
	uuid?: number[];
}

export interface TransportAccountMoveRequest {
	description?: string;
	to: number;
}

export interface TransportAccountMovieBalanceRequest {
	description?: string;
	value: number;
}

export interface TransportAccountRequest {
	account_meta?: DomainAccountMeta;
	currency?: string;
	description?: string;
	/**
	 * @minLength 0
	 * @maxLength 128
	 */
	name: string;
}

export interface TransportAccountSetCurrencyRequest {
	/**
	 * @minLength 0
	 * @maxLength 6
	 */
	currency?: string;
}

export interface TransportAccountSetPresetRequest {
	/**
	 * @minLength 0
	 * @maxLength 128
	 */
	preset_name?: string;
}

export interface TransportAccountUserCreateRequest {
	/**
	 * @minLength 0
	 * @maxLength 120
	 */
	full_name: string;
	/**
	 * @minLength 5
	 * @maxLength 64
	 */
	login: string;
	/**
	 * @minLength 5
	 * @maxLength 64
	 */
	password: string;
	type: DomainUserType;
}

export interface TransportAccountUserPasswordRequest {
	/**
	 * @minLength 4
	 * @maxLength 64
	 */
	new: string;
}

export interface TransportAccountUserUpdateRequest {
	/**
	 * @minLength 0
	 * @maxLength 120
	 */
	full_name: string;
	type: DomainUserType;
}

export interface TransportAdminGroupRequest {
	/**
	 * @minLength 5
	 * @maxLength 64
	 */
	name: string;
}

export interface TransportAdminRestriction {
	restrictions: string[];
}

export interface TransportAdminUserCreateRequest {
	/** @min 1 */
	account_id: number;
	/**
	 * @minLength 0
	 * @maxLength 120
	 */
	full_name: string;
	/**
	 * @minLength 5
	 * @maxLength 64
	 */
	login: string;
	/**
	 * @minLength 5
	 * @maxLength 64
	 */
	password: string;
	type: DomainUserType;
}

export interface TransportAdminUserGroup {
	groups: number[];
}

export interface TransportAdminUserUpdateRequest {
	/** @min 1 */
	account_id: number;
	/**
	 * @minLength 0
	 * @maxLength 120
	 */
	full_name: string;
	type: DomainUserType;
}

export interface TransportCashierBalanceResponse {
	places?: TransportCashierDevicePlace[];
}

export interface TransportCashierDevicePlace {
	balances?: {
		entries?: number;
		final_bonus?: number;
		winnings?: number;
	};
	currency?: string;
	/**
	 * @minLength 16
	 * @maxLength 64
	 */
	device: string;
	place: number;
}

export interface TransportCashierReportRequest {
	device_ids: string[];
	from: string;
	to: string;
}

export interface TransportCounterDeskRefillRequest {
	amount?: number;
}

export interface TransportDeviceResult {
	device?: DomainDevice;
	hex_uuid?: string;
}

export interface TransportEnumInt32Value {
	name?: string;
	value?: number;
}

export interface TransportJackpotGroupRequest {
	currency?: string;
	jp_key?: string;
	name?: string;
	service_id?: number;
}

export interface TransportMainReportRequest {
	/** @example [2] */
	account_ids: number[];
	/** @example "2021-11-18T00:00:00" */
	from: string;
	/** @example "2026-11-18T00:00:00" */
	to: string;
}

export interface TransportModBalanceRequest {
	delta?: DomainCashierBalances;
	trx?: string;
}

export interface TransportOperationReportRequest {
	/** @example ["2"] */
	device_ids: string[];
	/** @example "2021-11-18T00:00:00" */
	from: string;
	/** @example "2026-11-18T00:00:00" */
	to: string;
}

export interface TransportRegisterDeviceRequest {
	pin?: string;
	trx?: string;
}

export interface TransportUpdateDeviceRequest {
	active?: boolean;
	hidden?: boolean;
	name?: string;
}

export interface TransportUser {
	/** Root account */
	account?: DomainAccount;
	/** Root account ID */
	account_id?: number;
	/** User configuration */
	configuration?: DomainUserConfiguration;
	/** Created at */
	created_at?: string;
	email?: string;
	/** Full name */
	full_name?: string;
	/** User groups */
	groups?: DomainGroup[];
	id?: number;
	idx?: string;
	/** Language */
	language?: string;
	login?: string;
	restrictions?: string[];
	/** Time zone */
	time_zone?: string;
	/** User type */
	type?: DomainUserType;
}

export interface TransportUserChangerPasswordRequest {
	/**
	 * @minLength 4
	 * @maxLength 64
	 */
	new: string;
	/**
	 * @minLength 4
	 * @maxLength 64
	 */
	old: string;
}

export interface TransportUserProfile {
	groups?: string[];
	id?: string;
	/**
	 * @minLength 0
	 * @maxLength 6
	 */
	language?: string;
	login?: string;
	/**
	 * @minLength 0
	 * @maxLength 64
	 */
	name?: string;
	restrictions?: string[];
	/**
	 * @minLength 0
	 * @maxLength 6
	 */
	time_zone?: string;
	type?: DomainUserType;
}

export interface TransportUserSignInRequest {
	/**
	 * @minLength 3
	 * @maxLength 64
	 * @example "admin"
	 */
	login: string;
	/**
	 * @minLength 4
	 * @maxLength 64
	 * @example "admin"
	 */
	password: string;
	/** @example false */
	remember?: boolean;
}

export interface TransportWebError {
	data?: any;
	err?: string;
}

export interface TransportWebOK {
	ok?: boolean;
}

import type {
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
	HeadersDefaults,
	ResponseType,
} from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
	extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** request path */
	path: string;
	/** content type of request body */
	type?: ContentType;
	/** query params */
	query?: QueryParamsType;
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseType;
	/** request body */
	body?: unknown;
}

export type RequestParams = Omit<
	FullRequestParams,
	'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown>
	extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
	securityWorker?: (
		securityData: SecurityDataType | null
	) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
	secure?: boolean;
	format?: ResponseType;
}

export enum ContentType {
	Json = 'application/json',
	FormData = 'multipart/form-data',
	UrlEncoded = 'application/x-www-form-urlencoded',
	Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
	public instance: AxiosInstance;
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
	private secure?: boolean;
	private format?: ResponseType;

	constructor({
		securityWorker,
		secure,
		format,
		...axiosConfig
	}: ApiConfig<SecurityDataType> = {}) {
		this.instance = axios.create({
			...axiosConfig,
			baseURL: axiosConfig.baseURL || '',
		});
		this.secure = secure;
		this.format = format;
		this.securityWorker = securityWorker;
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected mergeRequestParams(
		params1: AxiosRequestConfig,
		params2?: AxiosRequestConfig
	): AxiosRequestConfig {
		const method = params1.method || (params2 && params2.method);

		return {
			...this.instance.defaults,
			...params1,
			...(params2 || {}),
			headers: {
				...((method &&
					this.instance.defaults.headers[
						method.toLowerCase() as keyof HeadersDefaults
					]) ||
					{}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {}),
			},
		};
	}

	protected stringifyFormItem(formItem: unknown) {
		if (typeof formItem === 'object' && formItem !== null) {
			return JSON.stringify(formItem);
		} else {
			return `${formItem}`;
		}
	}

	protected createFormData(input: Record<string, unknown>): FormData {
		if (input instanceof FormData) {
			return input;
		}
		return Object.keys(input || {}).reduce((formData, key) => {
			const property = input[key];
			const propertyContent: any[] =
				property instanceof Array ? property : [property];

			for (const formItem of propertyContent) {
				const isFileType = formItem instanceof Blob || formItem instanceof File;
				formData.append(
					key,
					isFileType ? formItem : this.stringifyFormItem(formItem)
				);
			}

			return formData;
		}, new FormData());
	}

	public request = async <T = any, _E = any>({
		secure,
		path,
		type,
		query,
		format,
		body,
		...params
	}: FullRequestParams): Promise<AxiosResponse<T>> => {
		const secureParams =
			((typeof secure === 'boolean' ? secure : this.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const responseFormat = format || this.format || undefined;

		if (
			type === ContentType.FormData &&
			body &&
			body !== null &&
			typeof body === 'object'
		) {
			body = this.createFormData(body as Record<string, unknown>);
		}

		if (
			type === ContentType.Text &&
			body &&
			body !== null &&
			typeof body !== 'string'
		) {
			body = JSON.stringify(body);
		}

		return this.instance.request({
			...requestParams,
			headers: {
				...(requestParams.headers || {}),
				...(type ? { 'Content-Type': type } : {}),
			},
			params: query,
			responseType: responseFormat,
			data: body,
			url: path,
		});
	};
}

/**
 * @title Cubegames admin API
 * @version 1.0
 * @license Apache 2.0 (http://www.apache.org/licenses/LICENSE-2.0.html)
 * @contact API Support <support@swagger.io> (http://www.swagger.io/support)
 *
 * Cubegames admin api backend server
 */
export class Api<
	SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
	a = {
		/**
		 * @description Return group.
		 *
		 * @tags admin
		 * @name AdminAccountSetRestrictionsCreate
		 * @summary Set account restrictions
		 * @request POST:/a/admin/account/{account_id}/set_restrictions
		 */
		adminAccountSetRestrictionsCreate: (
			accountId: number,
			request: TransportAdminRestriction,
			params: RequestParams = {}
		) =>
			this.request<TransportAccount, TransportWebError>({
				path: `/a/admin/account/${accountId}/set_restrictions`,
				method: 'POST',
				body: request,
				format: 'json',
				...params,
			}),

		/**
		 * @description get all community jackpots
		 *
		 * @tags community jackpots
		 * @name AdminCommunityJackpotsList
		 * @summary List all community jackpots
		 * @request GET:/a/admin/community_jackpots/
		 */
		adminCommunityJackpotsList: (params: RequestParams = {}) =>
			this.request<DomainCommunityJackpot[], TransportWebError>({
				path: `/a/admin/community_jackpots/`,
				method: 'GET',
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description create community jackpot
		 *
		 * @tags community jackpots
		 * @name AdminCommunityJackpotsCommunityJackpotCreate
		 * @summary Create community jackpot
		 * @request POST:/a/admin/community_jackpots/community_jackpot
		 */
		adminCommunityJackpotsCommunityJackpotCreate: (
			request: DomainCommunityJackpot,
			params: RequestParams = {}
		) =>
			this.request<DomainCommunityJackpot, TransportWebError>({
				path: `/a/admin/community_jackpots/community_jackpot`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description update community jackpot
		 *
		 * @tags community jackpots
		 * @name AdminCommunityJackpotsCommunityJackpotUpdate
		 * @summary Update community jackpot
		 * @request PUT:/a/admin/community_jackpots/community_jackpot/
		 */
		adminCommunityJackpotsCommunityJackpotUpdate: (
			request: DomainCommunityJackpot,
			params: RequestParams = {}
		) =>
			this.request<DomainCommunityJackpot, TransportWebError>({
				path: `/a/admin/community_jackpots/community_jackpot/`,
				method: 'PUT',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description delete community jackpot
		 *
		 * @tags community jackpots
		 * @name AdminCommunityJackpotsCommunityJackpotDelete
		 * @summary Delete community jackpot
		 * @request DELETE:/a/admin/community_jackpots/community_jackpot/{cjp_id}
		 */
		adminCommunityJackpotsCommunityJackpotDelete: (
			cjpId: number,
			params: RequestParams = {}
		) =>
			this.request<TransportWebError, TransportWebError>({
				path: `/a/admin/community_jackpots/community_jackpot/${cjpId}`,
				method: 'DELETE',
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description get community jp by id
		 *
		 * @tags community jackpots
		 * @name AdminCommunityJackpotsDetail
		 * @summary Get community jp by id
		 * @request GET:/a/admin/community_jackpots/{cjp_id}
		 */
		adminCommunityJackpotsDetail: (cjpId: number, params: RequestParams = {}) =>
			this.request<DomainCommunityJackpot, TransportWebError>({
				path: `/a/admin/community_jackpots/${cjpId}`,
				method: 'GET',
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return account list.
		 *
		 * @tags admin
		 * @name AdminDeviceIdsList
		 * @summary Get device from ids-string
		 * @request GET:/a/admin/device_ids
		 */
		adminDeviceIdsList: (
			query: {
				/** ids */
				ids: string;
			},
			params: RequestParams = {}
		) =>
			this.request<TransportDeviceResult, TransportWebError>({
				path: `/a/admin/device_ids`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * @description get all final_bonus
		 *
		 * @tags final_bonus
		 * @name AdminFinalBonusList
		 * @summary List all final_bonus
		 * @request GET:/a/admin/final_bonus/
		 */
		adminFinalBonusList: (params: RequestParams = {}) =>
			this.request<DomainFinalBonus[], TransportWebError>({
				path: `/a/admin/final_bonus/`,
				method: 'GET',
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description create final_bonus
		 *
		 * @tags final_bonus
		 * @name AdminFinalBonusCreate
		 * @summary Create final_bonus
		 * @request POST:/a/admin/final_bonus/
		 */
		adminFinalBonusCreate: (
			request: DomainFinalBonus,
			params: RequestParams = {}
		) =>
			this.request<DomainFinalBonus, TransportWebError>({
				path: `/a/admin/final_bonus/`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description get final_bonus by id
		 *
		 * @tags final_bonus
		 * @name AdminFinalBonusDetail
		 * @summary Get final_bonus by id
		 * @request GET:/a/admin/final_bonus/{fb_id}
		 */
		adminFinalBonusDetail: (fbId: number, params: RequestParams = {}) =>
			this.request<DomainFinalBonus, TransportWebError>({
				path: `/a/admin/final_bonus/${fbId}`,
				method: 'GET',
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description update final_bonus
		 *
		 * @tags final_bonus
		 * @name AdminFinalBonusUpdate
		 * @summary Update final_bonus
		 * @request PUT:/a/admin/final_bonus/{fb_id}
		 */
		adminFinalBonusUpdate: (
			fbId: number,
			request: DomainFinalBonus,
			params: RequestParams = {}
		) =>
			this.request<DomainFinalBonus, TransportWebError>({
				path: `/a/admin/final_bonus/${fbId}`,
				method: 'PUT',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description delete final_bonus
		 *
		 * @tags final_bonus
		 * @name AdminFinalBonusDelete
		 * @summary Delete final_bonus
		 * @request DELETE:/a/admin/final_bonus/{fb_id}
		 */
		adminFinalBonusDelete: (fbId: number, params: RequestParams = {}) =>
			this.request<TransportWebError, TransportWebError>({
				path: `/a/admin/final_bonus/${fbId}`,
				method: 'DELETE',
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return generators list.
		 *
		 * @tags admin
		 * @name AdminGeneratorList
		 * @summary Get all generators
		 * @request GET:/a/admin/generator
		 */
		adminGeneratorList: (params: RequestParams = {}) =>
			this.request<DomainGenerator[], TransportWebError>({
				path: `/a/admin/generator`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Return generator.
		 *
		 * @tags admin
		 * @name AdminGeneratorCreate
		 * @summary Create generator
		 * @request POST:/a/admin/generator/
		 */
		adminGeneratorCreate: (
			request: DomainGenerator,
			params: RequestParams = {}
		) =>
			this.request<DomainGenerator, TransportWebError>({
				path: `/a/admin/generator/`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return generator.
		 *
		 * @tags admin
		 * @name AdminGeneratorDetail
		 * @summary Get generator
		 * @request GET:/a/admin/generator/{gen_id}
		 */
		adminGeneratorDetail: (genId: number, params: RequestParams = {}) =>
			this.request<DomainGenerator, TransportWebError>({
				path: `/a/admin/generator/${genId}`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Return generator.
		 *
		 * @tags admin
		 * @name AdminGeneratorUpdate
		 * @summary Update generator
		 * @request PUT:/a/admin/generator/{gen_id}
		 */
		adminGeneratorUpdate: (
			genId: number,
			request: DomainGenerator,
			params: RequestParams = {}
		) =>
			this.request<DomainGenerator, TransportWebError>({
				path: `/a/admin/generator/${genId}`,
				method: 'PUT',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags admin
		 * @name AdminGeneratorDelete
		 * @summary Delete user
		 * @request DELETE:/a/admin/generator/{gen_id}
		 */
		adminGeneratorDelete: (genId: number, params: RequestParams = {}) =>
			this.request<string, TransportWebError>({
				path: `/a/admin/generator/${genId}`,
				method: 'DELETE',
				format: 'json',
				...params,
			}),

		/**
		 * @description Return group list.
		 *
		 * @tags admin
		 * @name AdminGroupList
		 * @summary Get group list
		 * @request GET:/a/admin/group
		 */
		adminGroupList: (params: RequestParams = {}) =>
			this.request<DomainGroup[], TransportWebError>({
				path: `/a/admin/group`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Return group.
		 *
		 * @tags admin
		 * @name AdminGroupCreate
		 * @summary Create group
		 * @request POST:/a/admin/group
		 */
		adminGroupCreate: (
			request: TransportAdminGroupRequest,
			params: RequestParams = {}
		) =>
			this.request<DomainGroup, TransportWebError>({
				path: `/a/admin/group`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return group.
		 *
		 * @tags admin
		 * @name AdminGroupDetail
		 * @summary Get group detail
		 * @request GET:/a/admin/group/{group_id}
		 */
		adminGroupDetail: (groupId: number, params: RequestParams = {}) =>
			this.request<DomainGroup[], TransportWebError>({
				path: `/a/admin/group/${groupId}`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Return group.
		 *
		 * @tags admin
		 * @name AdminGroupUpdate
		 * @summary Update project
		 * @request PUT:/a/admin/group/{group_id}
		 */
		adminGroupUpdate: (
			groupId: number,
			request: TransportAdminGroupRequest,
			params: RequestParams = {}
		) =>
			this.request<DomainGroup, TransportWebError>({
				path: `/a/admin/group/${groupId}`,
				method: 'PUT',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags admin
		 * @name AdminGroupDelete
		 * @summary Delete group
		 * @request DELETE:/a/admin/group/{group_id}
		 */
		adminGroupDelete: (groupId: number, params: RequestParams = {}) =>
			this.request<string, TransportWebError>({
				path: `/a/admin/group/${groupId}`,
				method: 'DELETE',
				format: 'json',
				...params,
			}),

		/**
		 * @description Return group.
		 *
		 * @tags admin
		 * @name AdminGroupSetRestrictionsCreate
		 * @summary Add group restriction
		 * @request POST:/a/admin/group/{group_id}/set_restrictions
		 */
		adminGroupSetRestrictionsCreate: (
			groupId: number,
			request: TransportAdminRestriction,
			params: RequestParams = {}
		) =>
			this.request<DomainGroup, TransportWebError>({
				path: `/a/admin/group/${groupId}/set_restrictions`,
				method: 'POST',
				body: request,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return account list.
		 *
		 * @tags admin
		 * @name AdminIdsList
		 * @summary Get ids from string
		 * @request GET:/a/admin/ids
		 */
		adminIdsList: (
			query: {
				/** ids */
				ids: string;
			},
			params: RequestParams = {}
		) =>
			this.request<number[], TransportWebError>({
				path: `/a/admin/ids`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * @description get all jackpots-groups
		 *
		 * @tags jackpots
		 * @name AdminJackpotsList
		 * @summary List all jackpots-groups
		 * @request GET:/a/admin/jackpots/
		 */
		adminJackpotsList: (
			query?: {
				/** Global */
				global?: boolean;
			},
			params: RequestParams = {}
		) =>
			this.request<DomainJackpotGroup[], TransportWebError>({
				path: `/a/admin/jackpots/`,
				method: 'GET',
				query: query,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description create jackpot-group
		 *
		 * @tags jackpots
		 * @name AdminJackpotsCreate
		 * @summary Create jackpot-group
		 * @request POST:/a/admin/jackpots/
		 */
		adminJackpotsCreate: (
			request: TransportJackpotGroupRequest,
			params: RequestParams = {}
		) =>
			this.request<DomainJackpotGroup, TransportWebError>({
				path: `/a/admin/jackpots/`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description get preset by id
		 *
		 * @tags jackpots
		 * @name AdminJackpotsDetail
		 * @summary Get preset by id
		 * @request GET:/a/admin/jackpots/{jpg_id}
		 */
		adminJackpotsDetail: (jpgId: number, params: RequestParams = {}) =>
			this.request<DomainJackpotGroup, TransportWebError>({
				path: `/a/admin/jackpots/${jpgId}`,
				method: 'GET',
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description update jackpot-group
		 *
		 * @tags jackpots
		 * @name AdminJackpotsUpdate
		 * @summary Update jackpot-group
		 * @request PUT:/a/admin/jackpots/{jpg_id}
		 */
		adminJackpotsUpdate: (
			jpgId: number,
			request: DomainJackpotGroup,
			params: RequestParams = {}
		) =>
			this.request<DomainJackpotGroup, TransportWebError>({
				path: `/a/admin/jackpots/${jpgId}`,
				method: 'PUT',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description delete jackpot-group
		 *
		 * @tags jackpots
		 * @name AdminJackpotsDelete
		 * @summary Delete jackpot-group
		 * @request DELETE:/a/admin/jackpots/{jpg_id}
		 */
		adminJackpotsDelete: (jpgId: number, params: RequestParams = {}) =>
			this.request<TransportWebError, TransportWebError>({
				path: `/a/admin/jackpots/${jpgId}`,
				method: 'DELETE',
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description create jackpot
		 *
		 * @tags jackpots
		 * @name AdminJackpotsJackpotCreate
		 * @summary Create jackpot
		 * @request POST:/a/admin/jackpots/{jpg_id}/jackpot
		 */
		adminJackpotsJackpotCreate: (
			jpgId: number,
			request: DomainJackpot,
			params: RequestParams = {}
		) =>
			this.request<DomainJackpot, TransportWebError>({
				path: `/a/admin/jackpots/${jpgId}/jackpot`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Update jackpot
		 *
		 * @tags jackpots
		 * @name AdminJackpotsJackpotUpdate
		 * @summary Update jackpot
		 * @request PUT:/a/admin/jackpots/{jpg_id}/jackpot/{jp_id}
		 */
		adminJackpotsJackpotUpdate: (
			jpgId: number,
			jpId: number,
			request: DomainJackpot,
			params: RequestParams = {}
		) =>
			this.request<DomainJackpot, TransportWebError>({
				path: `/a/admin/jackpots/${jpgId}/jackpot/${jpId}`,
				method: 'PUT',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Delete jackpot
		 *
		 * @tags jackpots
		 * @name AdminJackpotsJackpotDelete
		 * @summary Delete jackpot
		 * @request DELETE:/a/admin/jackpots/{jpg_id}/jackpot/{jp_id}
		 */
		adminJackpotsJackpotDelete: (
			jpgId: number,
			jpId: number,
			params: RequestParams = {}
		) =>
			this.request<TransportWebOK, TransportWebError>({
				path: `/a/admin/jackpots/${jpgId}/jackpot/${jpId}`,
				method: 'DELETE',
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description get all presets
		 *
		 * @tags presets
		 * @name AdminPresetList
		 * @summary List all presets
		 * @request GET:/a/admin/preset/
		 */
		adminPresetList: (params: RequestParams = {}) =>
			this.request<DomainPreset[], TransportWebError>({
				path: `/a/admin/preset/`,
				method: 'GET',
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description create preset
		 *
		 * @tags presets
		 * @name AdminPresetCreate
		 * @summary Create preset
		 * @request POST:/a/admin/preset/
		 */
		adminPresetCreate: (request: DomainPreset, params: RequestParams = {}) =>
			this.request<DomainPreset, TransportWebError>({
				path: `/a/admin/preset/`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description get preset by id
		 *
		 * @tags presets
		 * @name AdminPresetDetail
		 * @summary Get preset by id
		 * @request GET:/a/admin/preset/{preset_id}
		 */
		adminPresetDetail: (presetId: number, params: RequestParams = {}) =>
			this.request<DomainPreset, TransportWebError>({
				path: `/a/admin/preset/${presetId}`,
				method: 'GET',
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description update preset
		 *
		 * @tags presets
		 * @name AdminPresetUpdate
		 * @summary Update preset
		 * @request PUT:/a/admin/preset/{preset_id}
		 */
		adminPresetUpdate: (
			presetId: number,
			request: DomainPreset,
			params: RequestParams = {}
		) =>
			this.request<DomainPreset, TransportWebError>({
				path: `/a/admin/preset/${presetId}`,
				method: 'PUT',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description delete preset
		 *
		 * @tags presets
		 * @name AdminPresetDelete
		 * @summary Delete preset
		 * @request DELETE:/a/admin/preset/{preset_id}
		 */
		adminPresetDelete: (presetId: number, params: RequestParams = {}) =>
			this.request<TransportWebError, TransportWebError>({
				path: `/a/admin/preset/${presetId}`,
				method: 'DELETE',
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return user list.
		 *
		 * @tags admin
		 * @name AdminUserList
		 * @summary Get all users
		 * @request GET:/a/admin/user
		 */
		adminUserList: (
			query?: {
				/** Account ID */
				account_id?: number;
			},
			params: RequestParams = {}
		) =>
			this.request<TransportUser[], TransportWebError>({
				path: `/a/admin/user`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return user.
		 *
		 * @tags admin
		 * @name AdminUserCreate
		 * @summary Create user
		 * @request POST:/a/admin/user/
		 */
		adminUserCreate: (
			request: TransportAdminUserCreateRequest,
			params: RequestParams = {}
		) =>
			this.request<DomainUser, TransportWebError>({
				path: `/a/admin/user/`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return user.
		 *
		 * @tags admin
		 * @name AdminUserDetail
		 * @summary Get user
		 * @request GET:/a/admin/user/{user_id}
		 */
		adminUserDetail: (userId: number, params: RequestParams = {}) =>
			this.request<TransportUser, TransportWebError>({
				path: `/a/admin/user/${userId}`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Return user.
		 *
		 * @tags admin
		 * @name AdminUserUpdate
		 * @summary Update user
		 * @request PUT:/a/admin/user/{user_id}
		 */
		adminUserUpdate: (
			userId: number,
			request: TransportAdminUserUpdateRequest,
			params: RequestParams = {}
		) =>
			this.request<DomainUser, TransportWebError>({
				path: `/a/admin/user/${userId}`,
				method: 'PUT',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags admin
		 * @name AdminUserDelete
		 * @summary Delete user
		 * @request DELETE:/a/admin/user/{user_id}
		 */
		adminUserDelete: (userId: number, params: RequestParams = {}) =>
			this.request<string, TransportWebError>({
				path: `/a/admin/user/${userId}`,
				method: 'DELETE',
				format: 'json',
				...params,
			}),

		/**
		 * @description Return user.
		 *
		 * @tags admin
		 * @name AdminUserSetGroupsCreate
		 * @summary Add use into groups
		 * @request POST:/a/admin/user/{user_id}/set_groups
		 */
		adminUserSetGroupsCreate: (
			userId: number,
			request: TransportAdminUserGroup,
			params: RequestParams = {}
		) =>
			this.request<DomainUser, TransportWebError>({
				path: `/a/admin/user/${userId}/set_groups`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return user.
		 *
		 * @tags admin
		 * @name AdminUserSetPasswordCreate
		 * @summary Set user password
		 * @request POST:/a/admin/user/{user_id}/set_password
		 */
		adminUserSetPasswordCreate: (
			userId: number,
			request: TransportAccountUserPasswordRequest,
			params: RequestParams = {}
		) =>
			this.request<TransportWebOK, TransportWebError>({
				path: `/a/admin/user/${userId}/set_password`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return user.
		 *
		 * @tags admin
		 * @name AdminUserSetRestrictionsCreate
		 * @summary Set user restriction
		 * @request POST:/a/admin/user/{user_id}/set_restrictions
		 */
		adminUserSetRestrictionsCreate: (
			userId: number,
			request: TransportAdminRestriction,
			params: RequestParams = {}
		) =>
			this.request<DomainUser, TransportWebError>({
				path: `/a/admin/user/${userId}/set_restrictions`,
				method: 'POST',
				body: request,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return device float-balance.
		 *
		 * @tags cashier
		 * @name CashierAccountBalanceList
		 * @summary Get float-balance for device
		 * @request GET:/a/cashier/account_balance
		 */
		cashierAccountBalanceList: (params: RequestParams = {}) =>
			this.request<DomainFloatBalanceResult, TransportWebError>({
				path: `/a/cashier/account_balance`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Return devices list.
		 *
		 * @tags cashier
		 * @name CashierDevicesList
		 * @summary Get devices
		 * @request GET:/a/cashier/devices/
		 */
		cashierDevicesList: (params: RequestParams = {}) =>
			this.request<DomainCashierDevice[], TransportWebError>({
				path: `/a/cashier/devices/`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Return device.
		 *
		 * @tags cashier
		 * @name CashierDevicesRegisterCreate
		 * @summary Register new device by pin
		 * @request POST:/a/cashier/devices/register
		 */
		cashierDevicesRegisterCreate: (
			request: TransportRegisterDeviceRequest,
			params: RequestParams = {}
		) =>
			this.request<DomainCashierDevice, TransportWebError>({
				path: `/a/cashier/devices/register`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return device balances and places.
		 *
		 * @tags cashier
		 * @name CashierDevicesDetail
		 * @summary Get balances for device
		 * @request GET:/a/cashier/devices/{device_id}
		 */
		cashierDevicesDetail: (deviceId: string, params: RequestParams = {}) =>
			this.request<TransportCashierBalanceResponse, TransportWebError>({
				path: `/a/cashier/devices/${deviceId}`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Return device.
		 *
		 * @tags cashier
		 * @name CashierDevicesCreate
		 * @summary Update device
		 * @request POST:/a/cashier/devices/{device_id}
		 */
		cashierDevicesCreate: (
			deviceId: string,
			request: TransportUpdateDeviceRequest,
			params: RequestParams = {}
		) =>
			this.request<DomainCashierDevice, TransportWebError>({
				path: `/a/cashier/devices/${deviceId}`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return device, place balances.
		 *
		 * @tags cashier
		 * @name CashierDevicesPlaceUpdateCreate
		 * @summary Update balances for device, place
		 * @request POST:/a/cashier/devices/{device_id}/place/{place_id}/update
		 */
		cashierDevicesPlaceUpdateCreate: (
			deviceId: string,
			placeId: number,
			request: TransportModBalanceRequest,
			params: RequestParams = {}
		) =>
			this.request<TransportCashierDevicePlace, TransportWebError>({
				path: `/a/cashier/devices/${deviceId}/place/${placeId}/update`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return report.
		 *
		 * @tags cashier
		 * @name CashierReportCreate
		 * @summary Get report
		 * @request POST:/a/cashier/report
		 */
		cashierReportCreate: (
			request: TransportCashierReportRequest,
			params: RequestParams = {}
		) =>
			this.request<DomainAccountOperationReportResult[], TransportWebError>({
				path: `/a/cashier/report`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Change user password
		 *
		 * @tags users
		 * @name ChangePasswordCreate
		 * @summary Change user password
		 * @request POST:/a/change_password
		 */
		changePasswordCreate: (
			request: TransportUserChangerPasswordRequest,
			params: RequestParams = {}
		) =>
			this.request<TransportWebOK, TransportWebError>({
				path: `/a/change_password`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get cashdesk operations
		 *
		 * @tags counters
		 * @name CountersCashDetail
		 * @summary Get cashdesk operations
		 * @request GET:/a/counters/{accountId}/cash
		 */
		countersCashDetail: (accountId: number, params: RequestParams = {}) =>
			this.request<DomainCounterDeskOperation[], TransportWebError>({
				path: `/a/counters/${accountId}/cash`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Refill cashdesk
		 *
		 * @tags counters
		 * @name CountersCashRefillCreate
		 * @summary Refill cashdesk
		 * @request POST:/a/counters/{accountId}/cash/refill
		 */
		countersCashRefillCreate: (
			accountId: number,
			request: TransportCounterDeskRefillRequest,
			params: RequestParams = {}
		) =>
			this.request<DomainCounterDesk, TransportWebError>({
				path: `/a/counters/${accountId}/cash/refill`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description currency List
		 *
		 * @tags dictionary
		 * @name DictionaryCurrencyList
		 * @summary currency List
		 * @request GET:/a/dictionary/currency
		 */
		dictionaryCurrencyList: (params: RequestParams = {}) =>
			this.request<TransportEnumInt32Value[], any>({
				path: `/a/dictionary/currency`,
				method: 'GET',
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description modules List
		 *
		 * @tags dictionary
		 * @name DictionaryModulesList
		 * @summary modules List
		 * @request GET:/a/dictionary/modules
		 */
		dictionaryModulesList: (params: RequestParams = {}) =>
			this.request<TransportEnumInt32Value[], any>({
				path: `/a/dictionary/modules`,
				method: 'GET',
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Restriction List
		 *
		 * @tags dictionary
		 * @name DictionaryRestrictionsList
		 * @summary Restriction List
		 * @request GET:/a/dictionary/restrictions
		 */
		dictionaryRestrictionsList: (params: RequestParams = {}) =>
			this.request<string[], any>({
				path: `/a/dictionary/restrictions`,
				method: 'GET',
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Clients List
		 *
		 * @tags dictionary
		 * @name DictionaryRtpList
		 * @summary Clients List
		 * @request GET:/a/dictionary/rtp
		 */
		dictionaryRtpList: (params: RequestParams = {}) =>
			this.request<ConfigClient[], any>({
				path: `/a/dictionary/rtp`,
				method: 'GET',
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description currency List
		 *
		 * @tags dictionary
		 * @name DictionaryUserTypesList
		 * @summary currency List
		 * @request GET:/a/dictionary/user_types
		 */
		dictionaryUserTypesList: (params: RequestParams = {}) =>
			this.request<TransportEnumInt32Value[], any>({
				path: `/a/dictionary/user_types`,
				method: 'GET',
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return finance list.
		 *
		 * @tags finance
		 * @name FinanceTransactionsList
		 * @summary Get finance data by dates
		 * @request GET:/a/finance/transactions/
		 */
		financeTransactionsList: (
			query: {
				/** From time (format: 2021-11-18T00:00:00) */
				from: string;
				/** To time (format: 2021-11-18T00:00:00) */
				to: string;
			},
			params: RequestParams = {}
		) =>
			this.request<DomainFinance[], TransportWebError>({
				path: `/a/finance/transactions/`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return finance.
		 *
		 * @tags finance
		 * @name FinanceTransactionsCreate
		 * @summary Create finance
		 * @request POST:/a/finance/transactions/
		 */
		financeTransactionsCreate: (
			request: DomainFinance,
			params: RequestParams = {}
		) =>
			this.request<DomainFinance, TransportWebError>({
				path: `/a/finance/transactions/`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return finance.
		 *
		 * @tags finance
		 * @name FinanceTransactionsDetail
		 * @summary Get finance
		 * @request GET:/a/finance/transactions/{fc_id}
		 */
		financeTransactionsDetail: (fcId: number, params: RequestParams = {}) =>
			this.request<DomainFinance, TransportWebError>({
				path: `/a/finance/transactions/${fcId}`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Return finance.
		 *
		 * @tags finance
		 * @name FinanceTransactionsUpdate
		 * @summary Update finance
		 * @request PUT:/a/finance/transactions/{fc_id}
		 */
		financeTransactionsUpdate: (
			fcId: number,
			request: DomainFinance,
			params: RequestParams = {}
		) =>
			this.request<DomainFinance, TransportWebError>({
				path: `/a/finance/transactions/${fcId}`,
				method: 'PUT',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags finance
		 * @name FinanceTransactionsDelete
		 * @summary Delete finance
		 * @request DELETE:/a/finance/transactions/{fc_id}
		 */
		financeTransactionsDelete: (fcId: number, params: RequestParams = {}) =>
			this.request<string, TransportWebError>({
				path: `/a/finance/transactions/${fcId}`,
				method: 'DELETE',
				format: 'json',
				...params,
			}),

		/**
		 * @description Return account list.
		 *
		 * @tags account
		 * @name ManagerAccountList
		 * @summary Get all account tree
		 * @request GET:/a/manager/account/
		 */
		managerAccountList: (params: RequestParams = {}) =>
			this.request<TransportAccount[], TransportWebError>({
				path: `/a/manager/account/`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Return account.
		 *
		 * @tags account
		 * @name ManagerAccountDetail
		 * @summary Get account
		 * @request GET:/a/manager/account/{account_id}
		 */
		managerAccountDetail: (accountId: number, params: RequestParams = {}) =>
			this.request<TransportAccount, TransportWebError>({
				path: `/a/manager/account/${accountId}`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Return account.
		 *
		 * @tags account
		 * @name ManagerAccountUpdate
		 * @summary Update account
		 * @request PUT:/a/manager/account/{account_id}
		 */
		managerAccountUpdate: (
			accountId: number,
			request: TransportAccountRequest,
			params: RequestParams = {}
		) =>
			this.request<TransportAccount, TransportWebError>({
				path: `/a/manager/account/${accountId}`,
				method: 'PUT',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return account.
		 *
		 * @tags account
		 * @name ManagerAccountCreate
		 * @summary Create sub-account
		 * @request POST:/a/manager/account/{account_id}
		 */
		managerAccountCreate: (
			accountId: number,
			request: TransportAccountRequest,
			params: RequestParams = {}
		) =>
			this.request<TransportAccount, TransportWebError>({
				path: `/a/manager/account/${accountId}`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags account
		 * @name ManagerAccountDelete
		 * @summary Delete account
		 * @request DELETE:/a/manager/account/{account_id}
		 */
		managerAccountDelete: (accountId: number, params: RequestParams = {}) =>
			this.request<string, TransportWebError>({
				path: `/a/manager/account/${accountId}`,
				method: 'DELETE',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags account
		 * @name ManagerAccountBalanceOverdraftDetail
		 * @summary Get account overdraft log
		 * @request GET:/a/manager/account/{account_id}/balance/overdraft
		 */
		managerAccountBalanceOverdraftDetail: (
			accountId: number,
			params: RequestParams = {}
		) =>
			this.request<string, TransportWebError>({
				path: `/a/manager/account/${accountId}/balance/overdraft`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags account
		 * @name ManagerAccountBalanceOverdraftCreate
		 * @summary Add or remove overdraft for account
		 * @request POST:/a/manager/account/{account_id}/balance/overdraft
		 */
		managerAccountBalanceOverdraftCreate: (
			accountId: number,
			request: TransportAccountAddOverdraftRequest,
			params: RequestParams = {}
		) =>
			this.request<string, TransportWebError>({
				path: `/a/manager/account/${accountId}/balance/overdraft`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags account
		 * @name ManagerAccountBalanceTransactionsDetail
		 * @summary Get account transaction log
		 * @request GET:/a/manager/account/{account_id}/balance/transactions
		 */
		managerAccountBalanceTransactionsDetail: (
			accountId: number,
			params: RequestParams = {}
		) =>
			this.request<string, TransportWebError>({
				path: `/a/manager/account/${accountId}/balance/transactions`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags account
		 * @name ManagerAccountBalanceTransactionsMoveCreate
		 * @summary Movie account balance from root to another account
		 * @request POST:/a/manager/account/{account_id}/balance/transactions/move
		 */
		managerAccountBalanceTransactionsMoveCreate: (
			accountId: number,
			request: TransportAccountMovieBalanceRequest,
			params: RequestParams = {}
		) =>
			this.request<string, TransportWebError>({
				path: `/a/manager/account/${accountId}/balance/transactions/move`,
				method: 'POST',
				body: request,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags account
		 * @name ManagerAccountBalanceTransactionsPageDetail
		 * @summary Get account transaction log
		 * @request GET:/a/manager/account/{account_id}/balance/transactions/page
		 */
		managerAccountBalanceTransactionsPageDetail: (
			accountId: number,
			query: {
				/** Page */
				page: number;
				/** Page Size */
				page_size: number;
			},
			params: RequestParams = {}
		) =>
			this.request<string, TransportWebError>({
				path: `/a/manager/account/${accountId}/balance/transactions/page`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags account
		 * @name ManagerAccountBalanceTransactionsWithdrawCreate
		 * @summary Withdraw account balance
		 * @request POST:/a/manager/account/{account_id}/balance/transactions/withdraw
		 */
		managerAccountBalanceTransactionsWithdrawCreate: (
			accountId: number,
			request: TransportAccountMovieBalanceRequest,
			params: RequestParams = {}
		) =>
			this.request<string, TransportWebError>({
				path: `/a/manager/account/${accountId}/balance/transactions/withdraw`,
				method: 'POST',
				body: request,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags account
		 * @name ManagerAccountBalanceVisibleDetail
		 * @summary Set account balance visible
		 * @request GET:/a/manager/account/{account_id}/balance/visible
		 */
		managerAccountBalanceVisibleDetail: (
			accountId: number,
			query?: {
				/** visible */
				visible?: boolean;
			},
			params: RequestParams = {}
		) =>
			this.request<string, TransportWebError>({
				path: `/a/manager/account/${accountId}/balance/visible`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags account
		 * @name ManagerAccountConfigDetail
		 * @summary Get account configuration
		 * @request GET:/a/manager/account/{account_id}/config
		 */
		managerAccountConfigDetail: (
			accountId: number,
			params: RequestParams = {}
		) =>
			this.request<string, TransportWebError>({
				path: `/a/manager/account/${accountId}/config`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags account
		 * @name ManagerAccountDevicesDetail
		 * @summary Get account final bonus configuration
		 * @request GET:/a/manager/account/{account_id}/devices
		 */
		managerAccountDevicesDetail: (
			accountId: number,
			query?: {
				/** include all */
				include?: boolean;
			},
			params: RequestParams = {}
		) =>
			this.request<string, TransportWebError>({
				path: `/a/manager/account/${accountId}/devices`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags account
		 * @name ManagerAccountDevicesUpdate
		 * @summary Get account final bonus configuration
		 * @request PUT:/a/manager/account/{account_id}/devices/{device_id}
		 */
		managerAccountDevicesUpdate: (
			accountId: number,
			deviceId: number,
			request: TransportAccountDevice,
			params: RequestParams = {}
		) =>
			this.request<string, TransportWebError>({
				path: `/a/manager/account/${accountId}/devices/${deviceId}`,
				method: 'PUT',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags account
		 * @name ManagerAccountDevicesDelete
		 * @summary Get account final bonus configuration
		 * @request DELETE:/a/manager/account/{account_id}/devices/{device_id}
		 */
		managerAccountDevicesDelete: (
			accountId: number,
			deviceId: number,
			params: RequestParams = {}
		) =>
			this.request<string, TransportWebError>({
				path: `/a/manager/account/${accountId}/devices/${deviceId}`,
				method: 'DELETE',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags account
		 * @name ManagerAccountFinalBonusConfigDetail
		 * @summary Get account final bonus configuration
		 * @request GET:/a/manager/account/{account_id}/final_bonus_config
		 */
		managerAccountFinalBonusConfigDetail: (
			accountId: number,
			params: RequestParams = {}
		) =>
			this.request<string, TransportWebError>({
				path: `/a/manager/account/${accountId}/final_bonus_config`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags account
		 * @name ManagerAccountJackpotConfigDetail
		 * @summary Get account jackpot configuration
		 * @request GET:/a/manager/account/{account_id}/jackpot_config
		 */
		managerAccountJackpotConfigDetail: (
			accountId: number,
			params: RequestParams = {}
		) =>
			this.request<string, TransportWebError>({
				path: `/a/manager/account/${accountId}/jackpot_config`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags account
		 * @name ManagerAccountMoveCreate
		 * @summary Movie account to another parent-account
		 * @request POST:/a/manager/account/{account_id}/move
		 */
		managerAccountMoveCreate: (
			accountId: number,
			request: TransportAccountMoveRequest,
			params: RequestParams = {}
		) =>
			this.request<string, TransportWebError>({
				path: `/a/manager/account/${accountId}/move`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return account.
		 *
		 * @tags account
		 * @name ManagerAccountRefreshFinalBonusDetail
		 * @summary Refresh final bonus preset
		 * @request GET:/a/manager/account/{account_id}/refresh_final_bonus
		 */
		managerAccountRefreshFinalBonusDetail: (
			accountId: number,
			params: RequestParams = {}
		) =>
			this.request<TransportAccount, TransportWebError>({
				path: `/a/manager/account/${accountId}/refresh_final_bonus`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Return account.
		 *
		 * @tags account
		 * @name ManagerAccountRefreshJackpotDetail
		 * @summary Set account jackpot
		 * @request GET:/a/manager/account/{account_id}/refresh_jackpot
		 */
		managerAccountRefreshJackpotDetail: (
			accountId: number,
			params: RequestParams = {}
		) =>
			this.request<TransportAccount, TransportWebError>({
				path: `/a/manager/account/${accountId}/refresh_jackpot`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Return account.
		 *
		 * @tags account
		 * @name ManagerAccountRefreshPresetDetail
		 * @summary Set account preset
		 * @request GET:/a/manager/account/{account_id}/refresh_preset
		 */
		managerAccountRefreshPresetDetail: (
			accountId: number,
			params: RequestParams = {}
		) =>
			this.request<TransportAccount, TransportWebError>({
				path: `/a/manager/account/${accountId}/refresh_preset`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Return account.
		 *
		 * @tags account
		 * @name ManagerAccountSetCurrencyCreate
		 * @summary Set account currency
		 * @request POST:/a/manager/account/{account_id}/set_currency
		 */
		managerAccountSetCurrencyCreate: (
			accountId: number,
			request: TransportAccountSetCurrencyRequest,
			params: RequestParams = {}
		) =>
			this.request<TransportAccount, TransportWebError>({
				path: `/a/manager/account/${accountId}/set_currency`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return account.
		 *
		 * @tags account
		 * @name ManagerAccountSetFinalBonusCreate
		 * @summary Set final bonus preset
		 * @request POST:/a/manager/account/{account_id}/set_final_bonus
		 */
		managerAccountSetFinalBonusCreate: (
			accountId: number,
			request: TransportAccountSetPresetRequest,
			params: RequestParams = {}
		) =>
			this.request<TransportAccount, TransportWebError>({
				path: `/a/manager/account/${accountId}/set_final_bonus`,
				method: 'POST',
				body: request,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return account.
		 *
		 * @tags account
		 * @name ManagerAccountSetJackpotCreate
		 * @summary Set account jackpot
		 * @request POST:/a/manager/account/{account_id}/set_jackpot
		 */
		managerAccountSetJackpotCreate: (
			accountId: number,
			request: TransportAccountSetPresetRequest,
			params: RequestParams = {}
		) =>
			this.request<TransportAccount, TransportWebError>({
				path: `/a/manager/account/${accountId}/set_jackpot`,
				method: 'POST',
				body: request,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return account.
		 *
		 * @tags account
		 * @name ManagerAccountSetPresetCreate
		 * @summary Set account preset
		 * @request POST:/a/manager/account/{account_id}/set_preset
		 */
		managerAccountSetPresetCreate: (
			accountId: number,
			request: TransportAccountSetPresetRequest,
			params: RequestParams = {}
		) =>
			this.request<TransportAccount, TransportWebError>({
				path: `/a/manager/account/${accountId}/set_preset`,
				method: 'POST',
				body: request,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return user list.
		 *
		 * @tags account
		 * @name ManagerAccountUsersDetail
		 * @summary Get all users for account
		 * @request GET:/a/manager/account/{account_id}/users
		 */
		managerAccountUsersDetail: (
			accountId: number,
			params: RequestParams = {}
		) =>
			this.request<TransportUserProfile[], TransportWebError>({
				path: `/a/manager/account/${accountId}/users`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Return user list.
		 *
		 * @tags account
		 * @name ManagerAccountUsersCreate
		 * @summary Create user for account
		 * @request POST:/a/manager/account/{account_id}/users
		 */
		managerAccountUsersCreate: (
			accountId: number,
			request: TransportAccountUserCreateRequest,
			params: RequestParams = {}
		) =>
			this.request<TransportUserProfile, TransportWebError>({
				path: `/a/manager/account/${accountId}/users`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return user list.
		 *
		 * @tags account
		 * @name ManagerAccountUsersUpdate
		 * @summary Update user for account
		 * @request PUT:/a/manager/account/{account_id}/users/{user_id}
		 */
		managerAccountUsersUpdate: (
			accountId: number,
			userId: string,
			request: TransportAccountUserUpdateRequest,
			params: RequestParams = {}
		) =>
			this.request<TransportUserProfile, TransportWebError>({
				path: `/a/manager/account/${accountId}/users/${userId}`,
				method: 'PUT',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return user list.
		 *
		 * @tags account
		 * @name ManagerAccountUsersPasswordUpdate
		 * @summary Update user for account
		 * @request PUT:/a/manager/account/{account_id}/users/{user_id}/password
		 */
		managerAccountUsersPasswordUpdate: (
			accountId: number,
			userId: string,
			request: TransportAccountUserPasswordRequest,
			params: RequestParams = {}
		) =>
			this.request<TransportWebOK, TransportWebError>({
				path: `/a/manager/account/${accountId}/users/${userId}/password`,
				method: 'PUT',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get user profile
		 *
		 * @tags users
		 * @name GetA
		 * @summary Get profile
		 * @request GET:/a/me
		 */
		getA: (params: RequestParams = {}) =>
			this.request<TransportUserProfile, TransportWebError>({
				path: `/a/me`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Update user profile
		 *
		 * @tags users
		 * @name PostA
		 * @summary Update user profile
		 * @request POST:/a/me
		 */
		postA: (request: TransportUserProfile, params: RequestParams = {}) =>
			this.request<TransportUserProfile, TransportWebError>({
				path: `/a/me`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Get selected user profile
		 *
		 * @tags users
		 * @name GetA2
		 * @summary Get selected user profile
		 * @request GET:/a/me/{uid}
		 * @originalName getA
		 * @duplicate
		 */
		getA2: (uid: number, params: RequestParams = {}) =>
			this.request<TransportUserProfile, TransportWebError>({
				path: `/a/me/${uid}`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Return device list.
		 *
		 * @tags report
		 * @name ReportsDeviceListCreate
		 * @summary Get device list for account
		 * @request POST:/a/reports/device_list
		 */
		reportsDeviceListCreate: (
			request: TransportMainReportRequest,
			params: RequestParams = {}
		) =>
			this.request<DomainAccountDeviceResult[], TransportWebError>({
				path: `/a/reports/device_list`,
				method: 'POST',
				body: request,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return full-stats report.
		 *
		 * @tags report
		 * @name ReportsFullReportCreate
		 * @summary Get full report
		 * @request POST:/a/reports/full_report
		 */
		reportsFullReportCreate: (
			request: TransportMainReportRequest,
			params: RequestParams = {}
		) =>
			this.request<DomainStatsAggregate[], TransportWebError>({
				path: `/a/reports/full_report`,
				method: 'POST',
				body: request,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return full-stats chard report.
		 *
		 * @tags report
		 * @name ReportsFullReportChartCreate
		 * @summary Get full stats chart report
		 * @request POST:/a/reports/full_report_chart
		 */
		reportsFullReportChartCreate: (
			request: TransportMainReportRequest,
			params: RequestParams = {}
		) =>
			this.request<DomainStatsChartData[], TransportWebError>({
				path: `/a/reports/full_report_chart`,
				method: 'POST',
				body: request,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return main report.
		 *
		 * @tags report
		 * @name ReportsGroupCreate
		 * @summary Get group report
		 * @request POST:/a/reports/group
		 */
		reportsGroupCreate: (
			request: TransportMainReportRequest,
			params: RequestParams = {}
		) =>
			this.request<DomainAccountMainReportResult[], TransportWebError>({
				path: `/a/reports/group`,
				method: 'POST',
				body: request,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return main report.
		 *
		 * @tags report
		 * @name ReportsGroupOperationCreate
		 * @summary Get group report
		 * @request POST:/a/reports/group_operation
		 */
		reportsGroupOperationCreate: (
			request: TransportMainReportRequest,
			params: RequestParams = {}
		) =>
			this.request<DomainAccountOperationGroupResult[], TransportWebError>({
				path: `/a/reports/group_operation`,
				method: 'POST',
				body: request,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return main report.
		 *
		 * @tags report
		 * @name ReportsMainCreate
		 * @summary Get main report
		 * @request POST:/a/reports/main
		 */
		reportsMainCreate: (
			request: TransportMainReportRequest,
			params: RequestParams = {}
		) =>
			this.request<DomainAccountMainReportResult[], TransportWebError>({
				path: `/a/reports/main`,
				method: 'POST',
				body: request,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return main report.
		 *
		 * @tags report
		 * @name ReportsOperationCreate
		 * @summary Get group report
		 * @request POST:/a/reports/operation
		 */
		reportsOperationCreate: (
			request: TransportMainReportRequest,
			params: RequestParams = {}
		) =>
			this.request<DomainAccountOperationReportResult[], TransportWebError>({
				path: `/a/reports/operation`,
				method: 'POST',
				body: request,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return operation device report
		 *
		 * @tags report
		 * @name ReportsOperationDeviceCreate
		 * @summary Get operation device report
		 * @request POST:/a/reports/operation_device
		 */
		reportsOperationDeviceCreate: (
			request: TransportOperationReportRequest,
			params: RequestParams = {}
		) =>
			this.request<DomainAccountOperationReportResult[], TransportWebError>({
				path: `/a/reports/operation_device`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * @description Return simple-stats report.
		 *
		 * @tags report
		 * @name ReportsSimpleReportCreate
		 * @summary Get simple report
		 * @request POST:/a/reports/simple_report
		 */
		reportsSimpleReportCreate: (
			request: TransportMainReportRequest,
			params: RequestParams = {}
		) =>
			this.request<DomainStatsAggregate[], TransportWebError>({
				path: `/a/reports/simple_report`,
				method: 'POST',
				body: request,
				format: 'json',
				...params,
			}),

		/**
		 * @description Sign out
		 *
		 * @tags users
		 * @name SignOutList
		 * @summary Sign out
		 * @request GET:/a/sign_out
		 */
		signOutList: (params: RequestParams = {}) =>
			this.request<TransportWebOK, TransportWebError>({
				path: `/a/sign_out`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * @description Update user profile
		 *
		 * @tags account
		 * @name UsersGetTypeCountList
		 * @summary Update user profile
		 * @request GET:/a/users/get_type_count
		 */
		usersGetTypeCountList: (request: number[], params: RequestParams = {}) =>
			this.request<DomainUserAccountGroupByType[], TransportWebError>({
				path: `/a/users/get_type_count`,
				method: 'GET',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	};
	signIn = {
		/**
		 * @description Sign in
		 *
		 * @tags users
		 * @name SignInCreate
		 * @summary Sign in
		 * @request POST:/sign_in
		 */
		signInCreate: (
			request: TransportUserSignInRequest,
			params: RequestParams = {}
		) =>
			this.request<TransportUserProfile, TransportWebError>({
				path: `/sign_in`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	};
	time = {
		/**
		 * @description Return current time im ms.
		 *
		 * @tags common
		 * @name TimeList
		 * @summary Get current time
		 * @request GET:/time
		 */
		timeList: (params: RequestParams = {}) =>
			this.request<number, TransportWebError>({
				path: `/time`,
				method: 'GET',
				format: 'json',
				...params,
			}),
	};
}
