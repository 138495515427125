/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { ButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LineWave } from 'react-loader-spinner';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import AddDeviceModal from './components/AddDeviceModal/AddDeviceModal';
import DailyStatisticsTab from './components/DailyStatisticsTab/DailyStatisticsTab';
import DeviceButton from './components/DeviceButton/DeviceButton';
import DeviceDetailTab from './components/DeviceDetailTab/DeviceDetailTab';
import DeviceHistoryTab from './components/DeviceHistoryTab/DeviceHistoryTab';
import titleArrow from '../../assets/icons/titleArrow.svg';
import ButtonComponent from '../../components/common/Button/ButtonComponent';
import NavbarComponent from '../../components/common/Navbar/Navbar';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { baseAPI } from '../../services/baseAPI';
import { cashierAPI } from '../../services/CashierService';
import { TransportCashierBalanceResponse } from '../../shared/api/Api';
import showToastOnce from '../../utils/showToastOnce';
import './styles.scss';

const Devices: React.FC = () => {
	const { t } = useTranslation();
	const { user } = useAppSelector((state) => state.userReducer);

	const isDesktop = useMediaQuery({ query: '(min-width: 1351px)' });
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1350 });
	const isMobile = useMediaQuery({ maxWidth: 768 });
	const isntMobile = useMediaQuery({ minWidth: 1024 });

	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	// active DEVICES
	const { data: devices, error: devicesError } =
		cashierAPI.useFetchDevicesQuery(user?.id ?? '');

	// current SELECTED DEVICE
	const [currentDevice, setCurrentDevice] = useState<string>('');
	const visibleDevises = devices?.filter((device) => !device.hidden);
	const hiddenDevices = devices?.filter((device) => device.hidden);
	const [currentDeviceType, setCurrentDeviceType] = useState<string>('visible');

	// current SELECTED DETAILS
	const {
		data: details,
		error: detailsError,
		refetch: refetchDetails,
	} = cashierAPI.useFetchDeviceDetailQuery(currentDevice, {
		skip: currentDevice === '',
		pollingInterval: 5000,
		skipPollingIfUnfocused: false,
	});

	const [currentDetails, setCurrentDetails] = useState<
		TransportCashierBalanceResponse | undefined
	>(details);

	//dates and HISTORY
	const [fetchHistory, { data: history, isFetching: fetchingHistory }] =
		cashierAPI.useLazyFetchDeviceReportQuery();

	const handleFetchHistory = async (deviceId: string) => {
		const currentDate = dayjs().format('YYYY-MM-DD');
		const nextDate = dayjs().add(1, 'day').format('YYYY-MM-DD');

		try {
			await fetchHistory({
				device_ids: [deviceId],
				from: currentDate,
				to: nextDate,
			}).unwrap();
		} catch (error: any) {
			if (error.status === 401) {
				navigate('/authorization');
				showToastOnce(
					'error',
					t('toasts.error.cookieExpired'),
					'cookieExpired'
				);
				return () => {
					dispatch(baseAPI.util.resetApiState());
				};
			}
		}
	};

	const userHistory = history?.filter((story) => story.user_idx === user?.id);

	const dailyDeposit = userHistory?.reduce((acc, stat) => {
		return acc + (stat.data?.total_in || 0);
	}, 0);

	const dailyWithdraw = userHistory?.reduce((acc, stat) => {
		return acc + (stat.data?.total_out || 0);
	}, 0);

	// const dailyDelta = (dailyDeposit ?? 0) - (dailyWithdraw ?? 0);

	useEffect(() => {
		if (devicesError) {
			//@ts-ignore
			if (devicesError.status === 401) {
				navigate('/authorization');
				showToastOnce(
					'error',
					t('toasts.error.cookieExpired'),
					'cookieExpired'
				);
				return () => {
					dispatch(baseAPI.util.resetApiState());
				};
			}
		}
	}, [devicesError, navigate, t, dispatch]);

	useEffect(() => {
		if (detailsError) {
			//@ts-ignore
			if (detailsError.status === 401) {
				navigate('/authorization');
				showToastOnce(
					'error',
					t('toasts.error.cookieExpired'),
					'cookieExpired'
				);
				return () => {
					dispatch(baseAPI.util.resetApiState());
				};
			}
		}
	}, [detailsError, navigate, t, dispatch]);

	const [currentDeviceTab, setCurrentDeviceTab] = useState<string>('players');

	const [showAddDevice, setShowAddDevice] = useState<boolean>(false);
	const handleShowAddDevice = () => setShowAddDevice(!showAddDevice);

	useEffect(() => {
		if (details) {
			setCurrentDetails(details);
		}
	}, [details]);

	const showLoader = !currentDetails && currentDevice !== '';

	//AFK Checker
	const resetStates = () => {
		setCurrentDevice('');
		setCurrentDetails(undefined);
		setCurrentDeviceTab('players');
		setShowAddDevice(false);
	};

	useEffect(() => {
		const events = ['mousemove', 'keydown', 'click', 'scroll'];
		let afkTimeout: NodeJS.Timeout;

		const resetAfkTimer = () => {
			if (afkTimeout) clearTimeout(afkTimeout);
			afkTimeout = setTimeout(() => {
				resetStates();
			}, 180000);
		};

		const activityHandler = () => resetAfkTimer();

		events.forEach((event) => {
			window.addEventListener(event, activityHandler);
		});

		return () => {
			resetStates();
			events.forEach((event) => {
				window.removeEventListener(event, activityHandler);
			});
			if (afkTimeout) clearTimeout(afkTimeout);
		};
	}, []);

	//CURRENT DEVICE NAME
	const currentName = devices?.find(
		(device) => device.id === currentDevice
	)?.name;

	const formattedCurrentName =
		currentName && currentName.length > 15
			? isMobile
				? currentName.slice(0, 10) + '...'
				: !isntMobile
				? currentName.slice(0, 12) + '...'
				: currentName.slice(0, 25) + '...'
			: currentName;

	useEffect(() => {
		let intervalId: NodeJS.Timeout;

		if (currentDevice !== '') {
			intervalId = setInterval(() => {
				dispatch(cashierAPI.util.invalidateTags(['Float' as never]));
			}, 15000);
		}

		return () => clearInterval(intervalId);
	}, [currentDevice, dispatch]);

	return (
		<>
			<AddDeviceModal show={showAddDevice} handler={handleShowAddDevice} />
			<div className='cashierBG' />
			<NavbarComponent />
			<main>
				<div className='devices serviceBlock'>
					<div className='devicesList'>
						<div className='typeToggleAddDevice'>
							<ButtonGroup aria-label='deviceTypeToggler'>
								<ButtonComponent
									title={`${t('devices.deviceTypeToggler.visible')} ${
										(visibleDevises?.length ?? 0) > 0
											? visibleDevises?.length
											: ''
									}`}
									className='visibleToggle'
									color={`${
										currentDeviceType === 'visible' ? 'blue' : 'white'
									}`}
									action={() => setCurrentDeviceType('visible')}
								/>
								<ButtonComponent
									title={`${t('devices.deviceTypeToggler.hidden')} ${
										(hiddenDevices?.length ?? 0) > 0
											? hiddenDevices?.length
											: ''
									}`}
									className='hiddenToggle'
									color={`${currentDeviceType === 'hidden' ? 'blue' : 'white'}`}
									action={() => setCurrentDeviceType('hidden')}
									disabled={
										!user?.restrictions?.includes(
											/* 'can_delete_device' */ 'can_edit_device'
										)
									}
								/>
							</ButtonGroup>
							<ButtonComponent
								title={t('devices.addDevice')}
								className='addDeviceBtn'
								action={handleShowAddDevice}
								color='blue'
								disabled={
									!user?.restrictions?.includes('can_register_new_device')
								}
							/>
						</div>
						<div className='devicesTable'>
							<div className='titles'>
								<p>{t('devices.devicesTitles.game')}</p>
								<p>{t('devices.devicesTitles.id')}</p>
								<p>{t('devices.devicesTitles.name')}</p>
								<p>{t('devices.devicesTitles.players')}</p>
								<p>{t('devices.devicesTitles.payment')}</p>
							</div>
							<div className='devicesBtns'>
								{(currentDeviceType === 'visible'
									? visibleDevises
									: hiddenDevices
								)?.map((device, index) => (
									<DeviceButton
										id={device.id ?? t('devices.unknown')}
										key={index}
										name={device.name ?? ''}
										uuid={device.uuid ?? []}
										gameId={device.game_id ?? ''}
										hidden={device.hidden ?? false}
										gameModule={device.module ?? 0}
										// players={device.id as number}
										// payment={device.created_at ?? '0000:00:00'}
										currentDevice={
											currentDevice === (device.id ?? '') &&
											(isDesktop || isTablet)
										}
										action={
											isDesktop || isTablet
												? () => {
														setCurrentDevice(device.id ?? '');
														dispatch(
															cashierAPI.util.invalidateTags(['Float' as never])
														);
														if (device.id !== currentDevice) {
															setCurrentDetails(undefined);
														}
														if (currentDeviceTab === 'history') {
															handleFetchHistory(device.id ?? '');
														}
												  }
												: () => {
														dispatch(
															cashierAPI.util.invalidateTags(['Float' as never])
														);
														navigate(`/devices/${device.id}`);
												  }
										}
									/>
								))}
							</div>
						</div>
					</div>
					{(isDesktop || isTablet) && (
						<div className='deviceDetails'>
							<div className='detailsTable'>
								<div className='titleAndToggler'>
									<h1>
										{formattedCurrentName || t('devices.device')}{' '}
										{!currentName &&
											(currentDevice || t('devices.notSelected'))}
									</h1>
									<ButtonGroup aria-label='deviceStatisticToggler'>
										<ButtonComponent
											title={t('devices.detailsTitles.toggler.players')}
											className='playersToggle'
											color={`${
												currentDeviceTab === 'players' ? 'blue' : 'white'
											}`}
											action={() => setCurrentDeviceTab('players')}
										/>
										<ButtonComponent
											title={t('devices.detailsTitles.toggler.history')}
											className='historyToggle'
											color={`${
												currentDeviceTab === 'history' ? 'blue' : 'white'
											}`}
											action={() => {
												handleFetchHistory(currentDevice ?? '');
												setCurrentDeviceTab('history');
											}}
											disabled={
												/* !user?.restrictions?.includes(
													'can_view_report_daily'
												) || */ currentDevice === ''
											}
										/>
									</ButtonGroup>
								</div>
								{currentDeviceTab === 'history' &&
									!user?.restrictions?.includes('disable_daily_total') && (
										<div className='detailsHeader'>
											<DailyStatisticsTab
												title={t('devices.detailsHeaders.deposit')}
												count={((dailyDeposit ?? 0) / 100).toFixed(2)}
												deposit={true}
												// percent={24}
											/>
											<DailyStatisticsTab
												title={t('devices.detailsHeaders.withdraw')}
												count={(-(dailyWithdraw ?? 0) / 100).toFixed(2)}
												deposit={false}
												// percent={12}
											/>
											{/* <DailyStatisticsTab
												title={t('devices.detailsHeaders.delta')}
												count={((dailyDelta ?? 0) / 100).toFixed(2)}
												deposit={dailyDelta > 0}
												// percent={12}
											/> */}
										</div>
									)}
								<div className={`titles ${currentDeviceTab}`}>
									{currentDeviceTab === 'players' && (
										<>
											<p className='username'>
												{t('devices.detailsTitles.username')}
											</p>
											<p className='color'>
												{t('devices.detailsTitles.color')}
											</p>
											<p className='balance'>
												{t('devices.detailsTitles.balance')}
											</p>
											<p className='mock' />
										</>
									)}
									{currentDeviceTab === 'history' && (
										<>
											<p className='type'>{t('devices.detailsTitles.type')}</p>
											<p className='transactionId'>
												{isDesktop
													? t('devices.detailsTitles.transactionId')
													: t('devices.devicesTitles.id')}
											</p>
											<p className='player'>
												{t('devices.detailsTitles.player')}
											</p>
											<p className='amount'>
												{t('devices.detailsTitles.amount')}
											</p>
											<p className='date'>{t('devices.detailsTitles.date')}</p>
										</>
									)}
								</div>
								{currentDeviceTab === 'players' &&
									(showLoader ? (
										<div className='detailsTabs noPlaces'>
											<LineWave
												visible={true}
												height='100'
												width='100'
												color='#4fa94d'
												ariaLabel='line-wave-loading'
												wrapperStyle={{}}
												wrapperClass='lineWave'
												firstLineColor='#EB2F2F'
												middleLineColor='#3BBB26'
												lastLineColor='#3262DE'
											/>
										</div>
									) : (
										<div
											className={`detailsTabs ${
												currentDevice === '' ||
												(currentDetails?.places?.length ?? 0) === 0
													? 'noPlaces'
													: ''
											}`}
										>
											{currentDevice === '' ? (
												<div className='selectWithArrow'>
													{isDesktop && (
														<img
															src={titleArrow}
															alt='selectArrowIcon'
															className='selectArrowIcon'
														/>
													)}
													<p>{t('devices.detailsTab.noPlaces.selectDevice')}</p>
												</div>
											) : (currentDetails?.places?.length ?? 0) > 0 ? (
												currentDetails?.places?.map((place, index) => (
													<DeviceDetailTab
														key={index}
														{...place}
														setCurrentDetailsUndefined={() =>
															setCurrentDetails(undefined)
														}
														refetchDetails={refetchDetails}
													/>
												))
											) : (
												(currentDetails?.places?.length ?? 0) === 0 && (
													<p>
														{t('devices.detailsTab.noPlaces.inProgress')}
														<br />
														{t('devices.detailsTab.noPlaces.willBeDisplayed')}
													</p>
												)
											)}
										</div>
									))}
								{currentDeviceTab === 'history' &&
									(fetchingHistory ? (
										<div className='detailsTabs noPlaces'>
											<LineWave
												visible={true}
												height='100'
												width='100'
												color='#4fa94d'
												ariaLabel='line-wave-loading'
												wrapperStyle={{}}
												wrapperClass='lineWave'
												firstLineColor='#EB2F2F'
												middleLineColor='#3BBB26'
												lastLineColor='#3262DE'
											/>
										</div>
									) : (
										<div
											className={`detailsTabs ${
												(userHistory?.length ?? 0) === 0 ? 'noPlaces' : ''
											}`}
										>
											{currentDevice === '' ? (
												<div className='selectWithArrow'>
													{isDesktop && (
														<img
															src={titleArrow}
															alt='selectArrowIcon'
															className='selectArrowIcon'
														/>
													)}
													<p>{t('devices.detailsTab.noPlaces.selectDevice')}</p>
												</div>
											) : (userHistory?.length ?? 0) > 0 ? (
												userHistory?.map((place, index) => (
													<DeviceHistoryTab key={index} {...place} />
												))
											) : (
												<p>{t('devices.detailsTab.noPlaces.history')}</p>
											)}
										</div>
									))}
							</div>
						</div>
					)}
				</div>
			</main>
		</>
	);
};

export default Devices;
