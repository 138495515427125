const en = {
	translation: {
		toasts: {
			error: {
				noPage: 'The page you are looking for does not exist',
				unauthorized: 'You need to log in first',
				timeout:
					'Operation timeout. Something went wrong on the server side. Please contact technical support for assistance.',
				unknown:
					'Unknow error. Please contact technical support for assistance.',
				failedToCopy: 'Failed to copy {{string}} to clipboard',
				cookieExpired: 'Your session has expired. Please log in again.',
				forbidden: 'Forbidden',
				overdraft:
					'Account overdraft limit.<br />Balance: {{balance}}<br />Overdraft: {{overdraft}}',
				notEnough: `Sorry, player's balance isn't enough for this operation`,
				noDevices: 'No devices available',
				transactionLimit: {
					min: 'Oops! The amount is below the minimum required limit.<br />Minimum amount: {{min}}',
					max: `Oops! You've exceeded the maximum transaction limit.<br />Maximum amount: {{max}}`,
				},
				blocked: 'User blocked',
			},
			success: {
				deposit: 'Deposited successfully',
				withdraw: 'Withdrawn successfully',
				cashOut: 'Cashed out successfully',
				succeedToCopy: '{{string}} copied to clipboard',
				changedPassword: 'Password changed successfully',
			},
			requestErrors: {
				existingEmail: 'A user with this email already exists',
				serverError:
					'Internal Server Error. We apologize, we are already working on it',
				userNotFound:
					'User not found, please check if your login and/or password is correct',
				incorrectEmail: 'Check the spelling of your email',
				successful:
					'Registration was successful! You can log into your personal account using your username and password',
				noAccount: `User don't have an account`,
			},
		},
		modals: {
			addDevice: {
				titles: {
					title: 'Add device',
					description: 'To add device enter PIN code',
				},
				placeholders: {
					pin: 'PIN',
				},
				activate: 'Activate',
				success: 'The device {{string}} was added successfully',
				error: 'Error adding the device. Check the PIN code is correct',
				games: {
					rocket: 'Rocket Game',
					fruit: 'Fruit Game',
				},
			},
			cashManage: {
				manage: 'Manage',
				player: 'Player #',
				color: 'Color',
				balance: 'Balance',
				finalBonus: 'Final bonus available',
				quickNumbers: 'Quick numbers',
				sure: 'Are you sure you want to',
				for: 'for the',
				burn: 'final bonus will be burned',
				btns: {
					cashOut: 'Cash Out',
					deposit: 'Deposit',
					withdraw: 'Withdraw',
					cancel: 'Cancel',
				},
				placeholders: {
					amount: '0.00',
				},
			},
			editDevice: {
				approvementBlock: {
					title: 'Saving device changes',
					description:
						'Are you sure you want to save Device <span>{{string}}</span> edits?',
					btns: {
						back: 'Back',
						save: 'Save',
					},
				},
				mainForm: {
					device: 'Device',
					edit: 'edit',
					id: 'ID',
					uuid: 'UUID',
					gameId: 'Game ID',
					activeGame: 'Select an active game:',
					name: 'Name',
					positon: 'Position',
					btns: {
						cancel: 'Cancel',
						save: 'Save',
					},
					placeholders: {
						name: 'Device Name',
					},
				},
			},
			lock: {
				title: 'Till is locked',
				description: `To unlock, enter the cashier's password or log out of your current account`,
				forgot: 'If you forgot your password, contact the administrator',
				logOut: 'Log Out',
			},
			statistic: {
				totalDelta: 'Total delta',
				totalIncome: 'Total income',
				totalOutcome: 'Total outcome',
				totalOperations: 'Total operations',
				select: `Select dates and press 'Apply'`,
				labels: {
					from: 'From:',
					to: 'To:',
				},
				placeholders: {
					selectDate: `Select date`,
				},
				btns: {
					closeBtn: 'Close',
					saveBtn: 'Apply',
					resetBtn: 'Reset',
				},
			},
			changePassword: {
				title: 'Change password',
				old: 'Old password',
				new: 'New password',
				repeat: 'Repeat new password',
				placeholders: {
					old: 'Enter old password',
					new: 'Enter new password',
					repeat: 'Repeat new password',
				},
				errors: {
					required: '{{type}} password is required',
					max: '{{type}} password is too long',
					min: '{{type}} password is too short',
					mismatch: `New and repeated passwords don't match`,
				},
			},
		},
		navbar: {
			titles: {
				authorization: 'Authorization',
				id: 'ID',
				devices: 'Devices',
				history: 'History',
				statistic: 'Statistic',
				profile: 'Profile',
				yourId: 'Your ID',
			},
			btns: {
				lock: 'Lock',
				logout: 'Log out',
			},
			languages: {
				title: 'Languages',
				en: 'English',
				es: 'Spanish',
				pt: 'Portuguese',
			},
			gamePlatform: 'Game Platform',
			float: 'Float',
		},
		authorization: {
			serverError: 'The internal server error occured. Try again later.',
			loadingUser: 'Loading user...',
			placeholders: {
				login: 'Login',
				password: 'Password',
			},
			rememberMeCheck: 'Remember me',
			loginBtn: 'Login',
			errors: {
				login: {
					required: 'Enter your login',
					maxLength: 'Login is too long',
					minLength: 'Login is too short',
				},
				password: {
					required: 'Enter your password',
					maxLength: 'Password is too long',
					minLength: 'Password is too short',
				},
			},
			helpLinks: {
				forgotPassword: 'Forgot your password?',
				services: {
					title: 'Round-the-clock technical support service:',
					telegram: 'Telegram',
				},
			},
		},
		devices: {
			devicesList: 'Devices List',
			deviceTypeToggler: {
				visible: 'Visible',
				hidden: 'Hidden',
			},
			addDevice: '+ Add device',
			device: 'Device',
			unknown: 'unknown',
			notSelected: 'not selected',
			devicesTitles: {
				game: 'Game',
				name: 'Name',
				id: 'ID',
				players: 'Players',
				payment: 'Payment',
			},
			detailsHeaders: {
				deposit: '24h deposit',
				withdraw: '24h withdraw',
				delta: '24h delta',
			},
			detailsTitles: {
				username: 'Username',
				color: 'Color',
				balance: 'Balance',
				type: 'Type',
				transactionId: 'Transaction ID',
				player: 'Player',
				amount: 'Amount',
				date: 'Date',
				toggler: {
					players: 'Players',
					history: 'History',
				},
			},
			detailsTab: {
				player: 'Player',
				manage: 'Manage',
				noPlaces: {
					inProgress: 'Device registration in progress...',
					willBeDisplayed: 'Players will be displayed upon completion.',
					selectDevice: 'Select device',
					history: 'History is empty',
				},
			},
		},
		history: {
			btns: {
				operationType: {
					all: 'All',
					deposit: 'Deposit',
					withdraw: 'Withdraw',
				},
			},
			titles: {
				deviceName: 'Device name',
				deviceId: 'Device ID',
				player: 'Player',
				transactionId: 'Transaction ID',
				amount: 'Amount',
				type: 'Type',
				date: 'Date',
				print: 'Print',
			},
			sortAndFilter: 'Sort and filter',
			sortByDate: 'Sort by date:',
			fromNew: 'From newest to oldest',
			fromOld: 'From oldest to newest',
			filterByDevice: 'Filter by device:',
		},
	},
};

export default en;
