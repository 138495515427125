import React, { useEffect, useLayoutEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { useAppDispatch } from '../hooks/redux';
import Authorization from '../pages/Authorization/Authorization';
import DeviceDetailsMobile from '../pages/Devices/components/DeviceDetailsMobile/DeviceDetailsMobile';
import Devices from '../pages/Devices/Devices';
import GetClient from '../pages/GetClient/GetClient';
import History from '../pages/History/History';
import Redirect from '../pages/Redirect/Redirect';
import { baseAPI } from '../services/baseAPI';
import { userAPI } from '../services/UserService';
import { userSlice } from '../store/reducers/UserSlice';
import showToastOnce from '../utils/showToastOnce';

const NotFoundRedirect = () => {
	const { data: user, isLoading } = userAPI.useGetUserQuery(String(uuid));
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { t } = useTranslation();

	useEffect(() => {
		if (pathname !== '/') {
			showToastOnce('error', t('toasts.error.noPage'), 'noPage');
		}

		if (!isLoading) {
			if (user) {
				navigate('/devices');
			} else {
				navigate('/authorization');
				showToastOnce('error', t('toasts.error.unauthorized'), 'unauthorized');
			}
		}
	}, [pathname, navigate, t, user, isLoading]);

	return null;
};

const RoutesList = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { setUser, deleteUser } = userSlice.actions;
	const { pathname } = useLocation();
	const { t } = useTranslation();
	const { data: user, error } = userAPI.useGetUserQuery(String(uuid), {
		skip:
			pathname === '/authorization' ||
			pathname === '/maintenance-redirect' ||
			pathname === '/get-client',
	});

	useLayoutEffect(() => {
		if (user) {
			dispatch(setUser(user));
		}
	}, [user, dispatch, setUser]);

	if (error) {
		//@ts-ignore
		if (error.status === 'Internal server error' || error.status === 500) {
			return (
				<div className='errorText'>
					<h1>500</h1>
					<h4>{t('authorization.serverError')}</h4>
				</div>
			);
		} else if (
			//@ts-ignore
			error.status === 401 &&
			pathname !== '/authorization'
		) {
			navigate('/authorization');
			showToastOnce('error', t('toasts.error.unauthorized'), 'unauthorized');
			dispatch(deleteUser());
			dispatch(baseAPI.util.resetApiState());
		} else if (
			//@ts-ignore
			error.status !== 401 &&
			!user &&
			pathname !== '/authorization'
		) {
			return null;
		}
	}
	return (
		<Routes>
			<Route path='/authorization' element={<Authorization />} />
			<Route path='/devices' element={<Devices />} />
			<Route path='/devices/:deviceId' element={<DeviceDetailsMobile />} />
			<Route path='/history' element={<History />} />
			<Route path='*' element={<NotFoundRedirect />} />
			<Route path='/maintenance-redirect' element={<Redirect />} />
			<Route path='/get-client' element={<GetClient />} />
		</Routes>
	);
};

export default RoutesList;
