import React, { useEffect } from 'react';

import { Provider } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import i18n from '../../i18n';
import RoutesList from '../../routes/RoutesList';
import { setupStore } from '../../store/store';
import ScrollToTop from '../common/ScrollToTop';
import './styles.scss';

const store = setupStore();

const App: React.FC = () => {
	const isDesktop = useMediaQuery({ query: '(min-width: 1351px)' });
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1350 });

	useEffect(() => {
		const currentLang = i18n.language;
		document.documentElement.lang = currentLang;
	}, []);

	return (
		<Router
			basename={process.env.PUBLIC_URL}
			future={{
				v7_startTransition: true,
			}}
		>
			<Provider store={store}>
				<ToastContainer
					limit={1}
					draggable={true}
					closeOnClick={true}
					autoClose={1500}
				/>
				<ScrollToTop />
				<div
					className={`App ${
						isDesktop ? 'desktop' : isTablet ? 'tablet' : 'mobile'
					}`}
				>
					<RoutesList />
				</div>
			</Provider>
		</Router>
	);
};

export default App;
export { store };
