import React, { useEffect, useRef } from 'react';

import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { IInputWrapper } from '../../../utils/types';
import './styles.scss';

const InputWrapper: React.FC<IInputWrapper> = <T,>({
	name,
	placeholderRoute,
	icon,
	iconAlt,
	formik,
	type,
	action,
	maxLength,
	extraInputSign,
	isNumpad,
}: IInputWrapper<T>) => {
	const { t } = useTranslation();
	const isMobile = useMediaQuery({ maxWidth: 767 });

	// function to get the string error
	const getErrorText = (error: any): string | null => {
		if (typeof error === 'string') return error;
		if (Array.isArray(error) && typeof error[0] === 'string') return error[0];
		return null; // or return t('default.error.message') for common message
	};

	const handleInputOnNumpadChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		const { value } = e.target;

		// Regexp: only one dot and numbers, and only 2 numbers after the dot
		if (/^[0-9]*\.?[0-9]{0,2}$/.test(value)) {
			const currentValue = formik.values[name as keyof T] as string;

			if (value === '.' && currentValue === '') {
				// if dot is pressed and the field is empty
				formik.setFieldValue(name, '0.');
			} else if (!(currentValue.includes('.') && value === '.')) {
				// if there's dot, don't add another one
				formik.setFieldValue(name, value);
			}
		}

		// if dot is pressed and field is empty, set '0.'
		if (value.startsWith('.')) {
			formik.setFieldValue(name, '0' + value);
		}

		// if there're more symbols than 2, fix them to 2
		if (value.includes('.') && value.split('.')[1]?.length > 2) {
			formik.setFieldValue(name, value.slice(0, value.indexOf('.') + 3));
		}
	};

	const errorText = getErrorText(formik.errors[name as keyof T]);

	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (
			inputRef.current &&
			(name === 'pin' || name === 'amount' || name === 'name' || name === 'old')
		) {
			inputRef.current.focus();
		}
	}, [name]);

	return (
		<div className='input-wrapper'>
			<Form.Control
				name={name}
				placeholder={t(`${placeholderRoute}.placeholders.${name}`)}
				className={`custom-form-control ${
					formik.touched[name as keyof T] && formik.errors[name as keyof T]
						? 'is-invalid'
						: ''
				}`}
				type={type ?? 'text'}
				required
				value={formik.values[name as keyof T] as string}
				onChange={isNumpad ? handleInputOnNumpadChange : formik.handleChange}
				maxLength={maxLength}
				ref={inputRef}
				readOnly={isNumpad && isMobile ? true : false}
			/>
			{extraInputSign && <p className='extraInputSign'>{extraInputSign}</p>}
			{icon !== '' && (
				<img
					src={icon}
					alt={iconAlt}
					className={`inputIcon ${action && 'action'}`}
					onClick={action}
				/>
			)}
			{formik.touched[name as keyof T] && errorText && (
				<Form.Control.Feedback type='invalid' className='d-block'>
					{errorText}
				</Form.Control.Feedback>
			)}
		</div>
	);
};

export default InputWrapper;
