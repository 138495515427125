// import fs from 'fs';

import React, { useEffect /* useEffect , useState */ } from 'react';

// import { BetaAnalyticsDataClient } from '@google-analytics/data';
// import axios from 'axios';
// import { GoogleAuth } from 'google-auth-library';
import { Button } from 'react-bootstrap';
// import ReactGA from 'react-ga';

import // fetchAnalyticsData,
// initGapi,
// signIn,
// getAuthToken,
'./gapi';
import logo from '../../assets/logo.webp';
import './styles.scss';

/* const TRACKING_ID = 'G-7RBXVPG2WZ'; // OUR_TRACKING_ID
const initialaizeGA = () => {
	ReactGA.initialize(TRACKING_ID);
	console.log('initialized');
};

initialaizeGA(); */

const GetClient = () => {
	// document.documentElement.lang = 'en';
	useEffect(() => {
		// Создание тега <script> для загрузки Google Tag Manager
		const GAScript = document.createElement('script');
		GAScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-7RBXVPG2WZ';
		GAScript.async = true;
		document.head.appendChild(GAScript);

		// Создание основного кода Google Analytics
		const gaScript = document.createElement('script');
		gaScript.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-7RBXVPG2WZ');
        `;
		document.head.appendChild(gaScript);

		/* const YMScript = document.createElement('script');
		// YMScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-7RBXVPG2WZ';
		YMScript.type = 'text/javascript';
		YMScript.innerHTML = `
		(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   		m[i].l=1*new Date();
   		for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
   		k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
   		(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

   		ym(99967212, "init", {
		defer: true,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true
   		});
		`;
		document.head.appendChild(YMScript);

		const YMNoScript = document.createElement('noscript');
		// YMScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-7RBXVPG2WZ';
		// YMScript.type = 'text/javascript';
		YMScript.innerHTML = `
		<div><img src="https://mc.yandex.ru/watch/99967212" style="position:absolute; left:-9999px;" alt="" /></div>
		`;
		document.head.appendChild(YMNoScript); */

		/* axios
			.get(
				'https://api-metrika.yandex.net/stat/v1/data?metrics=ym:s:visits&id=99967212'
			)
			.then(function (response) {
				// handle success
				console.log(response);
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			}); */

		/* const scriptJS = document.createElement('script');
		script.src = 'https://apis.google.com/js/client:platform.js';
		script.async = true;
		document.head.appendChild(scriptJS); */

		// Очистка при размонтировании
		return () => {
			document.head.removeChild(GAScript);
			document.head.removeChild(gaScript);
			// document.head.removeChild(YMScript);
			// document.head.removeChild(YMNoScript);

			// document.head.removeChild(scriptJS);
		};
	}, []);

	/**
	 * TODO(developer): Uncomment this variable and replace with your
	 *   Google Analytics 4 property ID before running the sample.
	 */
	// const propertyId = '478455134';

	// Imports the Google Analytics Data API client library.

	// Using a default constructor instructs the client to use the credentials
	// specified in GOOGLE_APPLICATION_CREDENTIALS environment variable.
	// const analyticsDataClient = new BetaAnalyticsDataClient();

	// Runs a simple report.
	/* async function runReport() {
		const [response] = await analyticsDataClient.runReport({
			property: `properties/${propertyId}`,
			dateRanges: [
				{
					startDate: '2020-03-31',
					endDate: 'today',
				},
			],
			dimensions: [
				{
					name: 'city',
				},
			],
			metrics: [
				{
					name: 'activeUsers',
				},
			],
		});

		console.log('Report result:');
		response?.rows?.forEach((row) => {
			console.log(row?.dimensionValues?.[0], row?.metricValues?.[0]);
		});
	}

	runReport(); */

	/* const initAuth = () => {
		//@ts-ignore
		return window.gapi.auth2.init({
			client_id:
				'490306352518-3bdr100d0kpsegt7f25geovqvcrl60ts.apps.googleusercontent.com', //paste your client ID here
			scope: 'https://www.googleapis.com/auth/analytics.readonly',
		});
	};

	const checkSignedIn = () => {
		return new Promise((resolve, reject) => {
			initAuth() //calls the previous function
				.then(() => {
					//@ts-ignore
					const auth = window.gapi.auth2.getAuthInstance(); //returns the GoogleAuth object
					resolve(auth.isSignedIn.get()); //returns whether the current user is currently signed in
				})
				.catch((error: any) => {
					reject(error);
				});
		});
	};

	const renderButton = () => {
		//@ts-ignore
		window.gapi.signin2.render('signin-button', {
			scope: 'profile email',
			width: 240,
			height: 50,
			longtitle: true,
			theme: 'dark',
			onsuccess: onSuccess,
			onfailure: onFailure,
		});
	};

	const onSuccess = (googleUser: any) => {
		console.log('Logged in as: ' + googleUser.getBasicProfile().getName());
	};

	const onFailure = (error: any) => {
		console.error(error);
	};

	const [isSignedIn, setIsSignedIn] = useState(false);

	const updateSignin = (signedIn: any) => {
		//(3)
		setIsSignedIn(signedIn);
		if (!signedIn) {
			renderButton();
		}
	};

	const init = () => {
		//(2)
		checkSignedIn()
			.then((signedIn) => {
				updateSignin(signedIn);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	useEffect(() => {
		//@ts-ignore
		window.gapi.load('auth2', init); //(1)
	}); */

	/* const useAnalyticsEventTracker = (category = 'Download') => {
		const eventTracker = (
			action = 'download_client',
			label = 'download_client_label'
		) => {
			ReactGA.event({ category, action, label });
		};
		return eventTracker;
	};

	const gaEventTracker = useAnalyticsEventTracker('Download');

	useEffect(() => {
		gaEventTracker();
	}, [gaEventTracker]); */

	/* const credentials = JSON.parse(
		fs.readFileSync('../../src/stellar-451209-99b4a47d50a6.json').toString()
	);
	const propertyId = '478455134';

	const API_URL = `https://analyticsdata.googleapis.com/v1beta/properties/${propertyId}:runReport`;

	async function getAccessToken() {
		const auth = new GoogleAuth({
			credentials,
			scopes: ['https://www.googleapis.com/auth/analytics.readonly'],
		});

		const client = await auth.getClient();
		const accessToken = await client.getAccessToken();
		return accessToken.token;
	}

	async function fetchAnalyticsData() {
		try {
			const accessToken = await getAccessToken();

			const requestBody = {
				dateRanges: [{ startDate: '7daysAgo', endDate: 'today' }],
				metrics: [{ name: 'activeUsers' }],
				dimensions: [{ name: 'country' }], // Например, по странам
			};

			const response = await axios.post(API_URL, requestBody, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
					'Content-Type': 'application/json',
				},
			});

			console.log(
				'Ответ Google Analytics:',
				JSON.stringify(response.data, null, 2)
			);
		} catch (error: any) {
			console.error(
				'Ошибка при получении данных:',
				error.response?.data || error.message
			);
		}
	}

	fetchAnalyticsData(); */

	// const [data, setData] = useState(null); // needed, if use gapi with signIn

	/* useEffect(() => { // working but with signIn
		initGapi().then(() => {
			handleLogin();
		});
	}, []);

	const handleLogin = async () => {
		try {
			await signIn();
			// await getAuthToken();
			const analyticsData = await fetchAnalyticsData(); // Property ID из GA4
			setData(analyticsData);
		} catch (error) {
			console.error('❌ Ошибка авторизации:', error);
		}
	}; */

	return (
		<div className='getClientPage'>
			<img src={logo} alt='stellarBetsLogo' />
			<h4>
				Get your <span>Stellar Bets</span> client here:
			</h4>
			<Button
				className='downloadBtn'
				href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'
				target='_blank'
				rel='noreferrer'
				// onClick={() => gaEventTracker('download')}
				//@ts-ignore
				/* onClick={() => {
					//@ts-ignore
					ym(99967212, 'reachGoal', 'client-download');
					return true;
				}} */
			>
				Download
			</Button>
			{/* {!isSignedIn ? <div id='signin-button'></div> : <div>Coming soon...</div>} */}
			<div className='numbers'></div>
		</div>
	);
};

export default GetClient;
