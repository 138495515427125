import React from 'react';

import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import deleteIcon from '../../../../assets/icons/deleteIcon.svg';
import ButtonComponent from '../../../../components/common/Button/ButtonComponent';
import InputWrapper from '../../../../components/common/InputWrapper/InputWrapper';
import isNumberOrDot from '../../../../utils/isNumberOrDot';
import { INumpad } from '../../../../utils/types';
import './styles.scss';

const Numpad: React.FC<INumpad> = ({
	name,
	translationRoute,
	formik,
	numbers,
	maxLength,
	extraInputSign,
}) => {
	const { t } = useTranslation();

	const lastElementIsNumberOrDot = isNumberOrDot(numbers[numbers.length - 1]);

	const NumpadContent = (
		<>
			<InputWrapper
				name={name}
				placeholderRoute={translationRoute}
				icon={deleteIcon}
				iconAlt='deleteIcon'
				formik={formik}
				action={
					() => formik.setFieldValue(name, formik.values[name].slice(0, -1)) // Deletes last char
				}
				maxLength={maxLength}
				extraInputSign={extraInputSign}
				isNumpad={true}
			/>
			<div className='numbers'>
				{numbers.map((number) => {
					const isSubmitType = !isNumberOrDot(number);
					return (
						<ButtonComponent
							key={number}
							title={isSubmitType ? t(`${translationRoute}.${number}`) : number}
							className={isSubmitType ? 'activateBtn' : 'numBtn'}
							color={isSubmitType ? 'blue' : 'white'}
							type={isSubmitType ? 'submit' : 'button'}
							{...(!isSubmitType && {
								action: () => {
									const currentValue = formik.values[name];

									// if dot is pressed and field is empty, set '0.'
									if (number === '.' && currentValue === '') {
										formik.setFieldValue(name, '0.');
									} else if (!(currentValue.includes('.') && number === '.')) {
										// if dot exists don't add new one
										let newValue = currentValue + number;

										// if dot exists check char count after it
										if (newValue.includes('.')) {
											const decimalPart = newValue.split('.')[1]; // part after the dot
											if (decimalPart && decimalPart.length > 2) {
												// if there're more symbols than 2, fix them to 2
												newValue = newValue.slice(0, newValue.indexOf('.') + 3);
											}
										}

										formik.setFieldValue(name, newValue);
									}
								},
							})}
							disabled={
								formik.values[name].length === maxLength && !isSubmitType
							}
						/>
					);
				})}
			</div>
		</>
	);

	return lastElementIsNumberOrDot ? (
		<div className='numpad'>{NumpadContent}</div>
	) : (
		<Form className='numpad' onSubmit={formik.handleSubmit}>
			{NumpadContent}
		</Form>
	);
};

export default Numpad;
