import React, { Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';

import DropdownComponent from '../../../../components/common/Dropdown/Dropdown';
import './styles.scss';

interface IFilterByDeviceAndSortByDateProps {
	device_ids: string[];
	sortBy: 'new' | 'old';
	setSortBy: Dispatch<SetStateAction<'new' | 'old'>>;
	selectedDevice: string;
	setSelectedDevice: Dispatch<SetStateAction<string>>;
}

const FilterByDeviceAndSortByDate: React.FC<
	IFilterByDeviceAndSortByDateProps
> = ({ device_ids, sortBy, setSortBy, selectedDevice, setSelectedDevice }) => {
	const { t } = useTranslation();

	const sortByDateActionButtons = [
		{
			title: t('history.fromNew'),
			action: () => setSortBy('new'),
		},
		{
			title: t('history.fromOld'),
			action: () => setSortBy('old'),
		},
	];

	const selectDeviceButtons = [
		{
			title: t('history.btns.operationType.all'),
			action: () => setSelectedDevice('all'),
		},
		...device_ids
			.slice()
			.sort((a, b) => a.localeCompare(b))
			.map((device) => ({
				title: device,
				action: () => setSelectedDevice(device),
			})),
	];

	return (
		<div className='filterAndSortDropdown'>
			<div className='sorterWrapper'>
				<label>{t('history.sortByDate')}:</label>
				<DropdownComponent
					className='sortByDateDropdown'
					title={sortBy === 'new' ? t('history.fromNew') : t('history.fromOld')}
					icon=''
					iconAlt=''
					items={sortByDateActionButtons}
				/>
			</div>
			<div className='filterWrapper'>
				<label>{t('history.filterByDevice')}:</label>
				<DropdownComponent
					className='filterByDeviceDropdown'
					title={
						selectedDevice === 'all'
							? t('history.btns.operationType.all')
							: selectedDevice
					}
					icon=''
					iconAlt=''
					items={selectDeviceButtons}
				/>
			</div>
		</div>
	);
};

export default FilterByDeviceAndSortByDate;
