/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import { ButtonGroup } from 'react-bootstrap';
import { IDay } from 'react-calendar-datetime-picker/dist/types/type';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { timezones } from './components/FilterDateForm/consts';
import calendarIcon from '../../assets/icons/calendarIcon.svg';
import closeIcon from '../../assets/icons/closeIcon.svg';
import filterIcon from '../../assets/icons/filterIcon.svg';
import printIcon from '../../assets/icons/printIcon.svg';
import ButtonComponent from '../../components/common/Button/ButtonComponent';
import NavbarComponent from '../../components/common/Navbar/Navbar';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { baseAPI } from '../../services/baseAPI';
import { cashierAPI } from '../../services/CashierService';
import showToastOnce from '../../utils/showToastOnce';
import FilterByDeviceAndSortByDate from './components/FilterByDeviceAndSortByDate/FilterByDeviceAndSortByDate';
import FilterDateForm from './components/FilterDateForm/FilterDateForm';
import HistoryTable from './components/HistoryTable/HistoryTable';
import DropdownComponent from '../../components/common/Dropdown/Dropdown';
import './styles.scss';

const History = () => {
	const isDesktop = useMediaQuery({ query: '(min-width: 1351px)' });
	const isMobile = useMediaQuery({ maxWidth: 767 });
	const navigate = useNavigate();
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.userReducer);
	const { data: devices } = cashierAPI.useFetchDevicesQuery(user?.id ?? '');
	const [fetchHistory, { data: history, isFetching }] =
		cashierAPI.useLazyFetchDeviceReportQuery();

	const [operationType, setOperationType] = useState<
		'all' | 'deposit' | 'withdraw'
	>('all');
	const [sortBy, setSortBy] = useState<'new' | 'old'>('new');
	const [selectedDevice, setSelectedDevice] = useState<string>('all');

	const currentDate = dayjs();

	const dayStart = {
		year: currentDate.year(),
		month: currentDate.month() + 1,
		day: currentDate.date(),
		// hour: 0,
		// minute: 0,
		// second: 0,
	};

	const dayEnd = {
		year: currentDate.year(),
		month: currentDate.month() + 1,
		day: currentDate.date(),
		// hour: 23,
		// minute: 59,
		// second: 59,
	};

	const [lastFetchedDate, setLastFetchedDate] = useState<{
		from: IDay | null;
		to: IDay | null;
	}>({ from: dayStart, to: dayEnd });

	const [lastFetchedTime, setLastFetchedTime] = useState<{
		from: string;
		to: string;
	}>({ from: '00:00:00', to: '23:59:59' });

	const localTimezoneOffset = dayjs().format('Z');
	const [savedUtc, setSavedUtc] = useState<string>(localTimezoneOffset);

	const dateDropdownTitle = `${lastFetchedDate.from?.year}/${
		(lastFetchedDate.from?.month ?? 0) < 10
			? `0${lastFetchedDate.from?.month}`
			: lastFetchedDate.from?.month
	}/${
		(lastFetchedDate.from?.day ?? 0) < 10
			? `0${lastFetchedDate.from?.day}`
			: lastFetchedDate.from?.day
	} ${
		parseInt(lastFetchedTime.from.split(':')[0], 10) < 10
			? `0${parseInt(lastFetchedTime.from.split(':')[0], 10)}`
			: parseInt(lastFetchedTime.from.split(':')[0], 10)
	}:${
		parseInt(lastFetchedTime.from.split(':')[1], 10) < 10
			? `0${parseInt(lastFetchedTime.from.split(':')[1], 10)}`
			: parseInt(lastFetchedTime.from.split(':')[1], 10)
	} — ${lastFetchedDate.to?.year}/${
		(lastFetchedDate.to?.month ?? 0) < 10
			? `0${lastFetchedDate.to?.month}`
			: lastFetchedDate.to?.month
	}/${
		(lastFetchedDate.to?.day ?? 0) < 10
			? `0${lastFetchedDate.to?.day}`
			: lastFetchedDate.to?.day
	} ${
		parseInt(lastFetchedTime.to.split(':')[0], 10) < 10
			? `0${parseInt(lastFetchedTime.to.split(':')[0], 10)}`
			: parseInt(lastFetchedTime.to.split(':')[0], 10)
	}:${
		parseInt(lastFetchedTime.to.split(':')[1], 10) < 10
			? `0${parseInt(lastFetchedTime.to.split(':')[1], 10)}`
			: parseInt(lastFetchedTime.to.split(':')[1], 10)
	}, ${timezones.find((timezone) => timezone.value === savedUtc)?.title}`;

	const contentRef = useRef<HTMLDivElement>(null);
	const reactToPrintFn = useReactToPrint({
		contentRef,
		pageStyle: '@page { margin: 10px }',
	});

	useEffect(() => {
		if (devices) {
			const device_ids = devices?.map((device) => device.id ?? '');
			const from = /* '2024-11-18T00:00:00' */ dayjs()
				.hour(0)
				.minute(0)
				.second(0)
				.utc()
				.format('YYYY-MM-DDTHH:mm:ss');
			const to = dayjs()
				.hour(23)
				.minute(59)
				.second(59)
				.utc()
				.format('YYYY-MM-DDTHH:mm:ss');

			const handleFetchHistory = async () => {
				try {
					await fetchHistory({ device_ids, from, to });
				} catch (error: any) {
					if (error.status === 401) {
						navigate('/authorization');
						showToastOnce(
							'error',
							t('toasts.error.cookieExpired'),
							'cookieExpired'
						);
						return () => {
							dispatch(baseAPI.util.resetApiState());
						};
					}
				}
			};
			handleFetchHistory();
		}
	}, [devices, fetchHistory, dispatch, navigate, t]);

	const userHistory = history?.filter((story) => story.user_idx === user?.id);

	const filteredAndSortedHistory = React.useMemo(() => {
		const filteredHistory =
			selectedDevice !== 'all'
				? userHistory?.filter((item) => item.device_id === selectedDevice)
				: userHistory;

		return sortBy === 'new'
			? filteredHistory
			: [...(filteredHistory ?? [])].sort(
					(a, b) =>
						new Date(a.created_at ?? '').getTime() -
						new Date(b.created_at ?? '').getTime()
			  );
	}, [userHistory, selectedDevice, sortBy]);

	const depositHistory = userHistory?.filter(
		({ data }) => (data?.total_in ?? 0) > (data?.total_out ?? 0)
	);

	const filteredAndSortedDepositHistory = React.useMemo(() => {
		const filteredDepositHistory =
			selectedDevice !== 'all'
				? depositHistory?.filter((item) => item.device_id === selectedDevice)
				: depositHistory;

		return sortBy === 'new'
			? filteredDepositHistory
			: [...(filteredDepositHistory ?? [])].sort(
					(a, b) =>
						new Date(a.created_at ?? '').getTime() -
						new Date(b.created_at ?? '').getTime()
			  );
	}, [depositHistory, selectedDevice, sortBy]);

	const withdrawHistory = userHistory?.filter(
		({ data }) => (data?.total_in ?? 0) < (data?.total_out ?? 0)
	);

	const filteredAndSortedWithrawHistory = React.useMemo(() => {
		const filteredWidrawHistory =
			selectedDevice !== 'all'
				? withdrawHistory?.filter((item) => item.device_id === selectedDevice)
				: withdrawHistory;

		return sortBy === 'new'
			? filteredWidrawHistory
			: [...(filteredWidrawHistory ?? [])].sort(
					(a, b) =>
						new Date(a.created_at ?? '').getTime() -
						new Date(b.created_at ?? '').getTime()
			  );
	}, [withdrawHistory, selectedDevice, sortBy]);

	const [showDatepickerDropdown, setShowDatepickerDropdown] =
		useState<boolean>(false);

	const handleResetFilters = () => {
		setSelectedDevice('all');
		setSortBy('new');
		setLastFetchedDate({ from: dayStart, to: dayEnd });
		setSavedUtc(localTimezoneOffset);
	};

	useEffect(() => {
		const handleKeyUp = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				navigate('/devices');
			}
		};

		document.addEventListener('keyup', handleKeyUp);

		return () => {
			document.removeEventListener('keyup', handleKeyUp);
		};
	}, [navigate]);

	useEffect(() => {
		if (user && !user?.restrictions?.includes('can_view_history_reports')) {
			navigate('/devices');
			showToastOnce('error', t('toasts.error.noPage'), 'noPage');
		}
	});

	return (
		<>
			<div className='cashierBG' />
			<NavbarComponent />
			<main className='serviceBlock'>
				<div className='historyPage'>
					<div className='btns'>
						<ButtonGroup className='operationType'>
							<ButtonComponent
								title={`${t('history.btns.operationType.all')} ${
									filteredAndSortedHistory?.length ?? ''
								}`}
								color={operationType === 'all' ? 'blue' : 'white'}
								className='allBtn'
								action={() => setOperationType('all')}
							/>
							<ButtonComponent
								title={`${t('history.btns.operationType.deposit')} ${
									filteredAndSortedDepositHistory?.length ?? ''
								}`}
								color={operationType === 'deposit' ? 'green' : 'white'}
								className='depositBtn'
								action={() => setOperationType('deposit')}
							/>
							<ButtonComponent
								title={`${t('history.btns.operationType.withdraw')} ${
									filteredAndSortedWithrawHistory?.length ?? ''
								}`}
								color={operationType === 'withdraw' ? 'red' : 'white'}
								className='withdrawBtn'
								action={() => setOperationType('withdraw')}
							/>
						</ButtonGroup>
						{isDesktop ? (
							<div className='filtersDownloadPrint'>
								<div className='filters'>
									<DropdownComponent
										title={dateDropdownTitle}
										className='datepickerDropdown'
										icon={calendarIcon}
										iconAlt='calendarIcon'
										element={
											<FilterDateForm
												fetchHistory={fetchHistory}
												setLastFetchedDate={setLastFetchedDate}
												savedUtc={savedUtc}
												setSavedUtc={setSavedUtc}
												localTimezone={localTimezoneOffset}
												setShowDatepickerDropdown={setShowDatepickerDropdown}
												setLastFetchedTime={setLastFetchedTime}
											/>
										}
										show={showDatepickerDropdown}
										setShow={setShowDatepickerDropdown}
									/>
									<DropdownComponent
										title={t('history.sortAndFilter')}
										className='sortAndFilterDropdown'
										icon={filterIcon}
										iconAlt='filterIcon'
										element={
											<FilterByDeviceAndSortByDate
												device_ids={
													devices?.map((device) => device.id ?? '') ?? []
												}
												sortBy={sortBy}
												setSortBy={setSortBy}
												selectedDevice={selectedDevice}
												setSelectedDevice={setSelectedDevice}
											/>
										}
									/>
									<ButtonComponent
										className='resetFiltersBtn'
										icon={closeIcon}
										iconAlt='resetFiltersIcon'
										color='white'
										action={handleResetFilters}
									/>
								</div>
								<ButtonComponent
									className='printBtn'
									color='white'
									icon={printIcon}
									iconAlt='printIcon'
									action={() => reactToPrintFn()}
								/>
							</div>
						) : (
							<div className='filtersDownloadPrint'>
								<DropdownComponent
									title={dateDropdownTitle}
									className='datepickerDropdown'
									icon={calendarIcon}
									iconAlt='calendarIcon'
									element={
										<FilterDateForm
											fetchHistory={fetchHistory}
											setLastFetchedDate={setLastFetchedDate}
											savedUtc={savedUtc}
											setSavedUtc={setSavedUtc}
											localTimezone={localTimezoneOffset}
											setShowDatepickerDropdown={setShowDatepickerDropdown}
											setLastFetchedTime={setLastFetchedTime}
										/>
									}
									style={isMobile ? { fontSize: '12px' } : {}}
									show={showDatepickerDropdown}
									setShow={setShowDatepickerDropdown}
								/>
								<div className='sortPrint'>
									<DropdownComponent
										title={t('history.sortAndFilter')}
										className='sortAndFilterDropdown'
										icon={filterIcon}
										iconAlt='filterIcon'
										element={
											<FilterByDeviceAndSortByDate
												device_ids={
													devices?.map((device) => device.id ?? '') ?? []
												}
												sortBy={sortBy}
												setSortBy={setSortBy}
												selectedDevice={selectedDevice}
												setSelectedDevice={setSelectedDevice}
											/>
										}
									/>
									<ButtonComponent
										className='resetFiltersBtn'
										icon={closeIcon}
										iconAlt='resetFiltersIcon'
										color='white'
										action={handleResetFilters}
									/>
									<ButtonComponent
										className='printBtn'
										color='white'
										icon={printIcon}
										iconAlt='printIcon'
										action={() => reactToPrintFn()}
									/>
								</div>
							</div>
						)}
					</div>
					<HistoryTable
						contentRef={contentRef}
						isFetching={isFetching}
						operationType={operationType}
						history={filteredAndSortedHistory}
						depositHistories={filteredAndSortedDepositHistory}
						withdrawHistories={filteredAndSortedWithrawHistory}
						utc={savedUtc}
					/>
				</div>
			</main>
		</>
	);
};

export default History;
