import React, { useEffect, useState } from 'react';

import maintenanceIcon from '../../assets/maintenanceIcon.png';
import { Api, RequestParams, TransportWebError } from '../../shared/api/Api';
import './styles.scss';

const Redirect = () => {
	class ExtendedApi<SecurityDataType> extends Api<SecurityDataType> {
		maintenanceText = {
			fetchText: (
				filePath: string,
				authHeader: string,
				params: RequestParams = {}
			) =>
				this.request<string, TransportWebError>({
					path: filePath,
					method: 'GET',
					headers: {
						Authorization: authHeader,
					},
					format: 'text',
					...params,
				}),
		};
	}

	const [currentLanguage, setCurrentLanguage] = useState('');
	const [message, setMessage] = useState<string | undefined>();

	useEffect(() => {
		const browserLanguage = navigator.language || 'en';
		setCurrentLanguage(browserLanguage.split('-')[0]);
	}, []);

	useEffect(() => {
		const refreshInterval = setInterval(() => {
			window.location.reload();
		}, 30000);

		return () => {
			clearInterval(refreshInterval);
		};
	}, []);

	useEffect(() => {
		const checkJivoAPI = setInterval(() => {
			//@ts-ignore
			if (window.jivo_api) {
				//@ts-ignore
				jivo_destroy();
				clearInterval(checkJivoAPI);
			}
		}, 100);

		// stop checking in 10 sec
		const timeout = setTimeout(() => clearInterval(checkJivoAPI), 10000);

		return () => {
			clearInterval(checkJivoAPI);
			clearTimeout(timeout);
		};
	}, []);

	const api = new ExtendedApi({
		baseURL: '/update/message',
		withCredentials: true,
	});

	const authHeader = 'Basic cm9ja2V0LWNhc2hkZXNrOmVhMlZhZUxl';
	const filePath = '/test.txt';

	useEffect(() => {
		const fetchText = async () => {
			try {
				const response = await api.maintenanceText.fetchText(
					filePath,
					authHeader,
					{ maxRedirects: 0 }
				);
				setMessage(response.data);
			} catch (error: any) {
				console.error('Ошибка загрузки:', error);
				setMessage('');
			}
		};

		fetchText();
	}, [api.maintenanceText]);

	/* const getMaintenanceText = (lang: string) => {
		switch (lang) {
			case 'en':
				document.documentElement.lang = lang;
				return 'Technical work. We apologize for the inconvenience caused. Our team is actively working to make the service available to you again.';
			case 'fr':
				document.documentElement.lang = lang;
				return 'Nous effectuons des travaux techniques programmés et nous nous excusons pour les désagréments éventuels. Cela prendra au maximum une heure.';
			case 'fi':
				document.documentElement.lang = lang;
				return 'Meidän järjestämme suunniteltuja teknisiä töitä, pahoittelemme mahdollisia häiriöitä. Työ kestää enintään tunnin.';
			case 'zh':
				document.documentElement.lang = lang;
				return '我们正在进行计划中的技术维护，对可能带来的不便表示歉意，预计工作不超过一个小时。';
			case 'de':
				document.documentElement.lang = lang;
				return 'Wir führen geplante Wartungsarbeiten durch und entschuldigen uns für etwaige Unannehmlichkeiten. Dies wird nicht länger als eine Stunde dauern.';
			case 'es':
				document.documentElement.lang = lang;
				return 'Estamos realizando trabajos técnicos programados y nos disculpamos por cualquier inconveniente. Esto tomará no más de una hora.';
			default:
				document.documentElement.lang = 'en';
				return 'Technical work. We apologize for the inconvenience caused. Our team is actively working to make the service available to you again.';
		}
	}; */

	const get404Error = (lang: string) => {
		switch (lang) {
			case 'en':
				document.documentElement.lang = lang;
				return 'The resource requested could not be found on this server.';
			case 'fr':
				document.documentElement.lang = lang;
				return 'La ressource demandée est introuvable sur ce serveur.';
			case 'fi':
				document.documentElement.lang = lang;
				return 'Pyydettyä resurssia ei löytynyt tältä palvelimelta.';
			case 'zh':
				document.documentElement.lang = lang;
				return '请求的资源在此服务器上找不到。';
			case 'de':
				document.documentElement.lang = lang;
				return 'Die angeforderte Ressource wurde auf diesem Server nicht gefunden.';
			case 'es':
				document.documentElement.lang = lang;
				return 'El recurso solicitado no se pudo encontrar en este servidor.';
			default:
				document.documentElement.lang = 'en';
				return 'The resource requested could not be found on this server.';
		}
	};

	return (
		<div className='redirectPage'>
			{/* <h1>&nbsp;{getMaintenanceText(currentLanguage)}</h1> */}
			{message !== '' ? (
				<>
					<img src={maintenanceIcon} alt='maintenanceIcon' />
					{message && <h1>{message}</h1>}
				</>
			) : (
				<>
					<h1>404</h1>
					<h5>{get404Error(currentLanguage)}</h5>
				</>
			)}
		</div>
	);
};

export default Redirect;
