/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from 'react';

import { useFormik } from 'formik';
import { /* Button, */ Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LineWave } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

import { addDeviceNums } from './consts';
import addingError from '../../../../assets/icons/addingError.svg';
import addingSuccess from '../../../../assets/icons/addingSuccess.svg';
// import fruitGameIcon from '../../../../assets/icons/fruitIcon.svg';
// import rocketGameIcon from '../../../../assets/icons/rocketIcon.svg';
import ButtonComponent from '../../../../components/common/Button/ButtonComponent';
// import DropdownComponent from '../../../../components/common/Dropdown/Dropdown';
import { useAppDispatch } from '../../../../hooks/redux';
import { baseAPI } from '../../../../services/baseAPI';
import { cashierAPI } from '../../../../services/CashierService';
import showToastOnce from '../../../../utils/showToastOnce';
import { IModalProps } from '../../../../utils/types';
import Numpad from '../Numpad/Numpad';
import './styles.scss';

const validationSchema = yup.object().shape({
	pin: yup.string(),
});

const AddDeviceModal: React.FC<IModalProps> = ({ show, handler }) => {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [addingState, setAddingState] = useState<string>('');
	const [responseId, setResponseId] = useState<string>('');

	// const [activeGameIcon, setActiveGameIcon] = useState<string>(rocketGameIcon);
	// const [activeGame, setActiveGame] = useState<'rocket' | 'fruit'>('rocket');
	/* const gameItems = [
		{
			icon: rocketGameIcon,
			iconAlt: 'rocketGameIcon',
			title: 'Rocket Game',
			action: () => {
				setActiveGame('rocket');
				setActiveGameIcon(rocketGameIcon);
			},
		},
		{
			icon: fruitGameIcon,
			iconAlt: 'fruitGameIcon',
			title: 'Fruit Game',
			action: () => {
				setActiveGame('fruit');
				setActiveGameIcon(fruitGameIcon);
			},
		},
	]; */

	const [addDevice, { isLoading }] = cashierAPI.useAddDeviceMutation();

	const formik = useFormik({
		initialValues: {
			pin: '',
		},
		validationSchema,
		onSubmit: async (values) => {
			try {
				const newValues = {
					pin: values.pin,
					trx: uuidv4(),
				};

				const response = await addDevice(newValues).unwrap();
				setResponseId(response.id ?? t('devices.unknown'));
				formik.resetForm();
				setAddingState('success');
			} catch (error: any) {
				if (error.status === 401) {
					navigate('/authorization');
					showToastOnce(
						'error',
						t('toasts.error.cookieExpired'),
						'cookieExpired'
					);
					return () => {
						dispatch(baseAPI.util.resetApiState());
					};
				} else if (error.status === 'Forbidden') {
					setAddingState('error');
					showToastOnce('error', t('toasts.error.forbidden'), 'forbidden');
				} else if (error.status !== 'CUSTOM_ERROR') {
					setAddingState('error');
					showToastOnce('error', error.status, error.status);
				}
			}
		},
	});

	const handleClose = () => {
		formik.resetForm();
		setAddingState('');
		setResponseId('');
		handler();
	};

	return (
		<Modal
			show={show}
			onHide={isLoading ? () => undefined : handleClose}
			centered
			className={`addDeviceModal ${
				addingState.length === 0 ? 'hasNumpad' : 'noNumpad'
			}`}
		>
			{!isLoading && addingState.length === 0 && (
				<Modal.Header closeButton>
					<div className='title'>
						<h3>{t('modals.addDevice.titles.title')}</h3>
						<p>{t('modals.addDevice.titles.description')}</p>
					</div>
				</Modal.Header>
			)}
			<Modal.Body>
				{addingState.length > 0 ? (
					<>
						<img
							src={addingState === 'success' ? addingSuccess : addingError}
							alt={
								addingState === 'success'
									? 'addingSuccessIcon'
									: 'addingErrorIcon'
							}
							className={
								addingState === 'success'
									? 'addingSuccessIcon'
									: 'addingErrorIcon'
							}
						/>
						<p className={addingState === 'success' ? 'success' : 'error'}>
							{addingState === 'success'
								? t('modals.addDevice.success', {
										string: responseId,
								  })
								: t('modals.addDevice.error')}
						</p>
						<ButtonComponent
							title='OK'
							className='okBtn'
							color='white'
							action={
								addingState === 'success'
									? handleClose
									: () => {
											formik.resetForm();
											setAddingState('');
									  }
							}
						/>
					</>
				) : (
					<div className='numpadWrapper'>
						{isLoading ? (
							<LineWave
								height='100'
								width='100'
								ariaLabel='line-wave-loading'
								wrapperClass='lineWave'
								firstLineColor='#EB2F2F'
								middleLineColor='#3BBB26'
								lastLineColor='#3262DE'
							/>
						) : (
							<div className='gameNumpad'>
								{/* <DropdownComponent
									className={`activeGameDropdown ${activeGame}`}
									title={t(`modals.addDevice.games.${activeGame}`)}
									icon={activeGameIcon}
									iconAlt='activeGameIcon'
									items={gameItems}
								/> */}
								{/* <div className='gameList'>
									<Button
										className={`selectGameBtn rocketBtn ${
											activeGame === 'rocket' ? 'active' : ''
										}`}
										onClick={() => setActiveGame('rocket')}
									>
										<img
											src={rocketGameIcon}
											alt='rocketGameIcon'
											className='rocketGameIcon'
										/>
										// <p>{t('modals.addDevice.games.rocket')}</p>
									</Button>
									<Button
										className={`selectGameBtn fruitBtn ${
											activeGame === 'fruit' ? 'active' : ''
										}`}
										onClick={() => setActiveGame('fruit')}
									>
										<img
											src={fruitGameIcon}
											alt='fruitGameIcon'
											className='fruitGameIcon'
										/>
										// <p>{t('modals.addDevice.games.fruit')}</p>
									</Button>
								</div> */}
								<Numpad
									name='pin'
									translationRoute='modals.addDevice'
									formik={formik}
									numbers={addDeviceNums}
									maxLength={5}
								/>
								{/* <div className='gameList'>
									<Button className='selectGameBtn'>
										<p>{t('modals.addDevice.games.rocket')}</p>
										<img
											src={rocketGameIcon}
											alt='rocketGameIcon'
											className='rocketGameIcon'
										/>
									</Button>
									<Button className='selectGameBtn'>
										<p>{t('modals.addDevice.games.fruit')}</p>
										<img
											src={fruitGameIcon}
											alt='fruitGameIcon'
											className='fruitGameIcon'
										/>
									</Button>
								</div> */}
							</div>
						)}
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default AddDeviceModal;
