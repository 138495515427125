import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
	BaseQueryFn,
	FetchArgs,
	FetchBaseQueryError,
	FetchBaseQueryMeta,
	QueryDefinition,
} from '@reduxjs/toolkit/query';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import { DtPicker } from 'react-calendar-datetime-picker';
import { IDay } from 'react-calendar-datetime-picker/dist/types/type';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import TimePicker from 'react-time-picker';
import * as yup from 'yup';

import { timezones } from './consts';
import { store } from '../../../../components/App/App';
import ButtonComponent from '../../../../components/common/Button/ButtonComponent';
import DropdownComponent from '../../../../components/common/Dropdown/Dropdown';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { baseAPI } from '../../../../services/baseAPI';
import {
	DomainAccountOperationReportResult,
	DomainCashierDevice,
	TransportOperationReportRequest,
} from '../../../../shared/api/Api';
import reverseUtc from '../../../../utils/reverseUtc';
import showToastOnce from '../../../../utils/showToastOnce';
import './styles.scss';

interface IFilterDateForm {
	//@ts-ignore
	fetchHistory: LazyQueryTrigger<
		QueryDefinition<
			TransportOperationReportRequest,
			BaseQueryFn<
				string | FetchArgs,
				unknown,
				FetchBaseQueryError,
				object,
				FetchBaseQueryMeta
			>,
			never,
			DomainAccountOperationReportResult[],
			'baseAPI'
		>
	>;
	setLastFetchedDate: Dispatch<
		SetStateAction<{ from: IDay | null; to: IDay | null }>
	>;
	savedUtc: string;
	setSavedUtc: Dispatch<SetStateAction<string>>;
	localTimezone: string;
	setShowDatepickerDropdown: Dispatch<SetStateAction<boolean>>;
	setLastFetchedTime: Dispatch<SetStateAction<{ from: string; to: string }>>;
}

const FilterDateForm: React.FC<IFilterDateForm> = ({
	fetchHistory,
	setLastFetchedDate,
	savedUtc,
	setSavedUtc,
	localTimezone,
	setShowDatepickerDropdown,
	setLastFetchedTime,
}) => {
	const { user } = useAppSelector((state) => state.userReducer);
	const { t } = useTranslation();
	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	const state = store.getState();

	const devices = state.baseAPI.queries[`fetchDevices("${user?.id ?? ''}")`]
		?.data as DomainCashierDevice[];

	const device_ids = devices && devices.map((device) => device.id ?? '');

	const validationSchema = yup.object().shape({
		device_ids: yup.array().of(yup.string()).required(),
		fromDate: yup.string().trim().required(),
		fromTime: yup.string().trim().required(),
		toDate: yup.string().trim().required(),
		toTime: yup.string().trim().required(),
	});

	const formik = useFormik({
		initialValues: {
			device_ids: device_ids ?? [],
			fromDate: '',
			fromTime: '00:00:00',
			toDate: '',
			toTime: '23:59:59',
		},
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values) => {
			if (formik.values.device_ids.length === 0) {
				showToastOnce('error', t('toasts.error.noDevices'), 'noDevices');
				return;
			}

			const formattedValues = {
				device_ids,
				from: dayjs(values.fromDate)
					.hour(parseInt(values.fromTime.split(':')[0], 10))
					.minute(parseInt(values.fromTime.split(':')[1], 10))
					.second(parseInt(values.fromTime.split(':')[2], 10))
					.utcOffset(reverseUtc(selectedUtc))
					.format('YYYY-MM-DDTHH:mm:ss'),
				to: dayjs(values.toDate)
					.hour(parseInt(values.toTime.split(':')[0], 10))
					.minute(parseInt(values.toTime.split(':')[1], 10))
					.second(parseInt(values.toTime.split(':')[2], 10))
					.utcOffset(reverseUtc(selectedUtc))
					.format('YYYY-MM-DDTHH:mm:ss'),
			};

			try {
				setShowDatepickerDropdown(false);
				await fetchHistory(formattedValues);
				setLastFetchedDate({
					from: fromInitDate,
					to: toInitDate,
				});
				setLastFetchedTime({ from: values.fromTime, to: values.toTime });
				setSavedUtc(selectedUtc);
			} catch (error: any) {
				if (error.status === 401) {
					navigate('/authorization');
					showToastOnce(
						'error',
						t('toasts.error.cookieExpired'),
						'cookieExpired'
					);
					return () => {
						dispatch(baseAPI.util.resetApiState());
					};
				}
			}
		},
	});

	const currentDate = dayjs();

	const minDate = {
		year: 2024,
		month: 11,
		day: 18,
	};

	const maxDate = {
		year: currentDate.year(),
		month: currentDate.month() + 1,
		day: currentDate.date(),
	};

	const dayStart = {
		year: currentDate.year(),
		month: currentDate.month() + 1,
		day: currentDate.date(),
		//hour: 0,
		//minute: 0,
		//second: 0,
	};

	const dayEnd = {
		year: currentDate.year(),
		month: currentDate.month() + 1,
		day: currentDate.date(),
		//hour: 23,
		//minute: 59,
		//second: 59,
	};

	const [fromInitDate, setFromInitDate] = useState<IDay | null>(dayStart);
	const [toInitDate, setToInitDate] = useState<IDay | null>(dayEnd);

	const [selectedUtc, setSelectedUtc] = useState<string>(savedUtc);

	const handleDateChange = (date: IDay, field: string) => {
		const utcDate = dayjs
			.utc()
			.year(date.year)
			.month(date.month - 1)
			.date(date.day);
		// .hour(date.hour ?? 0)
		// .minute(date.minute ?? 0)
		// .second(field === 'from' ? 0 : 59);

		switch (field) {
			case 'fromDate':
				setFromInitDate(date);
				break;
			case 'toDate':
				setToInitDate(date);
				break;
		}

		formik.setFieldValue(field, utcDate);
	};

	const handleClearDates = async () => {
		const device_ids = devices?.map((device) => device.id ?? '');
		const from = /* '2024-11-18T00:00:00' */ dayjs()
			.hour(0)
			.minute(0)
			.second(0)
			.utc()
			.format('YYYY-MM-DDTHH:mm:ss');

		const to = dayjs()
			.hour(23)
			.minute(59)
			.second(59)
			.utc()
			.format('YYYY-MM-DDTHH:mm:ss');

		if (device_ids) {
			try {
				setFromInitDate(dayStart);
				setToInitDate(dayEnd);
				setLastFetchedDate({ from: dayStart, to: dayEnd });
				setLastFetchedTime({ from: '00:00:00', to: '23:59:59' });
				setSelectedUtc(localTimezone);
				setSavedUtc(localTimezone);
				setShowDatepickerDropdown(false);
				formik.setFieldValue('fromTime', '00:00:00');
				formik.setFieldValue('toTime', '23:59:59');

				await fetchHistory({ device_ids, from, to });
			} catch (error: any) {
				if (error.status === 401) {
					navigate('/authorization');
					showToastOnce(
						'error',
						t('toasts.error.cookieExpired'),
						'cookieExpired'
					);
					return () => {
						dispatch(baseAPI.util.resetApiState());
					};
				}
			}
		} else {
			showToastOnce('error', t('toasts.error.noDevices'), 'noDevices');
		}
	};

	const timezonesWithActions = timezones.map((timezone) => ({
		...timezone,
		action: () => setSelectedUtc(timezone.value),
	}));

	useEffect(() => {
		const resetFiltersBtn = document.querySelector('.resetFiltersBtn');

		if (resetFiltersBtn) {
			resetFiltersBtn.addEventListener('mouseup', handleClearDates);
		}

		return () => {
			if (resetFiltersBtn) {
				resetFiltersBtn.addEventListener('mouseup', handleClearDates);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Form className='datepickerInDropdown' onSubmit={formik.handleSubmit}>
			<div className='inputs'>
				<div className='datepickerWrapper'>
					<label>GMT:</label>
					<DropdownComponent
						className='utcDropdown'
						title={
							timezones.find((timezone) => timezone.value === selectedUtc)
								?.title ?? ''
						}
						icon=''
						iconAlt=''
						items={timezonesWithActions}
					/>
				</div>
				<div className='datepickerWrapper'>
					<label>{t('modals.statistic.labels.from')}</label>
					<div className='datetimeBlock'>
						<DtPicker
							onChange={(date) =>
								date
									? handleDateChange(date, 'fromDate')
									: formik.setFieldValue('fromDate', '')
							}
							placeholder={t('modals.statistic.placeholders.selectDate')}
							maxDate={maxDate}
							minDate={minDate}
							initValue={fromInitDate}
						/>
						<TimePicker
							onChange={(e) => formik.setFieldValue('fromTime', e)}
							value={formik.values.fromTime}
							format='HH:mm:ss'
							maxDetail='second'
							disableClock
							clearIcon={null}
						/>
					</div>
				</div>
				<div className='datepickerWrapper'>
					<label>{t('modals.statistic.labels.to')}</label>
					<div className='datetimeBlock'>
						<DtPicker
							onChange={(date) =>
								date
									? handleDateChange(date, 'toDate')
									: formik.setFieldValue('toDate', '')
							}
							placeholder={t('modals.statistic.placeholders.selectDate')}
							maxDate={maxDate}
							minDate={minDate}
							initValue={toInitDate}
						/>
						<TimePicker
							onChange={(e) => formik.setFieldValue('toTime', e)}
							value={formik.values.toTime}
							format='HH:mm:ss'
							maxDetail='second'
							disableClock
							clearIcon={null}
						/>
					</div>
				</div>
			</div>
			<div className='btns'>
				<ButtonComponent
					title={t('modals.statistic.btns.resetBtn')}
					color='white'
					className='clear'
					action={handleClearDates}
					/* disabled={
						formik.values.fromDate === '' && formik.values.toDate === ''
					} */
				/>
				<ButtonComponent
					title={t('modals.statistic.btns.saveBtn')}
					color='green'
					className='saveBtn'
					type='submit'
					disabled={
						formik.values.fromDate === '' ||
						formik.values.toDate === '' ||
						formik.values.fromTime === null ||
						formik.values.toTime === null
					}
				/>
			</div>
		</Form>
	);
};

export default FilterDateForm;
